import React, { FC, ReactChild, ReactNode } from 'react';
import { Card, CardBody, CardFooter, CardHeader } from '@adeccoux/tag-ds';
import { useT } from '@features/oneOnboarding/hooks/useT';
import { OOControlModelInterface } from '@features/oneOnboarding/interfaces';
import { Paper, Typography, useTheme } from '@mui/material';

type OOCardFieldProps = {
  headerText?: string;
  children?: ReactNode;
  footer?: ReactChild;
  controlModel?: OOControlModelInterface;
};

export const OOCard: FC<OOCardFieldProps> = ({ headerText, children, footer, controlModel }) => {
  const theme = useTheme();
  const { t } = useT('entry', 'approval', 'documents');

  return (
    <Paper
      elevation={2}
      sx={{
        boxShadow: '6px 4px 24px 0px #1C304B14',
        margin: '20px 5px',
        padding: '1.5em',
        fontFamily: theme.typography.fontFamily,
        lineHeight: '2em',
      }}
    >
      <Card key={headerText}>
        <CardHeader>
          <Typography variant="caption" fontWeight="bold" fontSize={20}>
            {controlModel?.label ? t(controlModel?.label) : headerText}
          </Typography>
        </CardHeader>
        <CardBody>
          {controlModel?.description && <Typography fontSize={16}> {t(controlModel?.description)}</Typography>}
          <div>{children}</div>
        </CardBody>
        <CardFooter>{footer}</CardFooter>
      </Card>
    </Paper>
  );
};
