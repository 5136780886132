import React, { useEffect, useMemo, VoidFunctionComponent } from 'react';
import { useFormikContext } from 'formik';
import AccordionWithAddButtonOpenModal from '@features/oneOnboarding/controls/fields/AccordionWithAddButtonOpenModal';
import { store } from '@store/index';

import { useCalculationClassUI } from '../hooks/useCalculationClassUI';
import { useRenderCondition } from '../hooks/useRenderCondition';
import { useT } from '../hooks/useT';
import {
  Component,
  OODateControlModelInterface,
  OOMultiSelectModelInterface,
  OOSelectorCheckboxModelInterface,
  OOSelectorDropdownModelInterface,
  TextFieldControlModelInterface,
} from '../interfaces';
import { OOControlModel } from '../models/ControlModel';
import { OOFlowWrapper } from '../wrappers/FlowWrapper';
import { OOAccordion } from './fields/Accordion/OOAccordion';
import { AccordionWithAddButton } from './fields/AccordionWithAddButton';
import AccordionWithSubcontrols from './fields/AccordionWithSubcontrols';
import { BooleanRadio } from './fields/BooleanRadio';
import { OOCard } from './fields/Card/OOCard';
import { CardWithButtons } from './fields/CardWithButton';
import ContainerWithSubcontrols from './fields/ContainerWithSubcontrols';
import OOContentSwitcher from './fields/ContentSwitcher/OOContentSwitcher';
import { DatePickerComponent } from './fields/DatePickerComponent';
import { DefaultAutoComplete } from './fields/DefaultAutoComplete';
import { OODefaultCheckbox } from './fields/DefaultCheckbox';
import { OODefaultConsentCheckbox } from './fields/DefaultConsentCheckbox';
import { DefaultConsentWithoutCheckmark } from './fields/DefaultConsentWithoutCheckmark';
import { DefaultDropdown } from './fields/DefaultDropdown';
import { DefaultRadioGroup } from './fields/DefaultRadioGroup';
import { OODefaultSwitch } from './fields/DefaultSwitch';
import { ExtendableAutoComplete } from './fields/ExtendableAutoComplete';
import { HtmlCustom } from './fields/HtmlCustom';
import { MultipleValuesRadio } from './fields/MultipleValuesRadio/MultipleValuesRadio';
import { Notification } from './fields/Notification/Notification';
import { OOCheckboxListHTML } from './fields/OOCheckboxListHTML';
import { OOImageComponent } from './fields/OOImageComponent';
import { OOMultiSelectComponent } from './fields/OOMultiSelectComponent';
import { OOSwitchWithInfo } from './fields/OOSwitchWithInfo';
import { OOTextFieldWithButton } from './fields/OOTextFieldWithButton';
import { OORankingSurvey } from './fields/RankingSurvey/OORankingSurvey';
import StaticText from './fields/StaticText/StaticText';
import { OOTextField } from './fields/TextField/OOTextField';

interface OOFormControlProps {
  control: OOControlModel;
  otherFormControls?: OOControlModel[];
  onChangeCallback?: () => void;
  filterSubControls?: (c: OOControlModel) => boolean;
  setManualErrors?: (errors: Map<string, any>) => void;
}

export const OOFormControl: VoidFunctionComponent<OOFormControlProps> = ({
  control,
  otherFormControls,
  onChangeCallback,
  filterSubControls,
  setManualErrors,
}) => {
  const { t } = useT('entry', 'documents', 'hiring', 'candidate_recruiter');
  const formik = useFormikContext<Record<string, any>>();
  const flowData = store.getState().oneOnboarding.flow;

  const flowWrapper = useMemo(() => {
    if (!flowData) return null;
    return OOFlowWrapper.create(flowData);
  }, [flowData]);

  const render = useRenderCondition(control, flowWrapper);
  const calcClass = useCalculationClassUI(
    control?.calculationClassUI,
    control,
    otherFormControls,
    formik,
    setManualErrors,
  );

  useEffect(() => {
    calcClass?.init?.(control.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const populateIsEditableFromParent = (control: OOControlModel, subControl: OOControlModel) => {
    subControl.isEditableCandidate = control.isEditableCandidate;
    subControl.isEditableRecruiter = control.isEditableRecruiter;
  };

  const evaluateIsEditableFromParent = () => {
    if (!control?.subControls?.length) return;
    for (let i = 0; i < control?.subControls?.length; i++) {
      const subControl = control.subControls[i] as OOControlModel;
      const subControlArray = control.subControls[i] as Array<OOControlModel>;

      if (subControl?.isEditableCandidate) {
        populateIsEditableFromParent(control, subControl);
      } else {
        for (let j = 0; j < subControlArray.length; j++) {
          const nestedSubControl = subControlArray[j];
          populateIsEditableFromParent(control, nestedSubControl);
        }
      }
    }
  };

  useMemo(() => {
    evaluateIsEditableFromParent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [control]);

  const onChange = (event: any) => {
    if (calcClass) {
      const allFormControls = otherFormControls || [];
      for (const c of allFormControls) {
        c.value = formik.values[c.name];
      }

      calcClass.onChange(event);
      if (onChangeCallback) {
        onChangeCallback();
      }
    }
  };

  if (!render) return <></>;

  const staticControls = {
    [Component.ContentSwitcher]: <OOContentSwitcher controlModel={control} />,
    [Component.Image]: <OOImageComponent controlModel={control} />,
    [Component.Html]: <HtmlCustom controlModel={control} />,
    [Component.Notification]: <Notification controlModel={control} />,
    [Component.StaticText]: <StaticText controlModel={control} />,
    [Component.Heading]: (
      <h3 className="tag-ds" style={{ marginBottom: '-4px' }}>
        {t(`${control.staticText}`)}
      </h3>
    ),
    [Component.ContainerWithSubcontrols]: (
      <ContainerWithSubcontrols controlModel={control} filterSubControls={filterSubControls} />
    ),
    // Its used in form, not here
    [Component.DismissButton]: <></>,
    [Component.SubmitButton]: <></>,
    [Component.SubmitLink]: <></>,
  };

  const radioControls = {
    [Component.Radio]: <BooleanRadio key={control.name} {...(control as OOSelectorCheckboxModelInterface)} />,
    [Component.RadioGroup]: <DefaultRadioGroup key={control.name} {...control} />,
    [Component.RadioWithHelp]: <MultipleValuesRadio iconType="HELP" key={control.name} {...control} />,
    [Component.RadioExpandOnSelect]: <MultipleValuesRadio expandOnSelect key={control.name} {...control} />,
    [Component.RadioMultipleValues]: <MultipleValuesRadio key={control.name} {...control} />,
  };

  const selectControls = {
    [Component.Select]: (
      <DefaultDropdown
        key={control.name}
        {...(control as OOSelectorDropdownModelInterface)}
        onChangeCallback={onChange}
      />
    ),
    [Component.MultiSelectWithCheckbox]: (
      <DefaultDropdown
        key={control.name}
        {...(control as OOSelectorDropdownModelInterface)}
        multiple={true}
        checkBoxItems={true}
        onChangeCallback={onChange}
      />
    ),
    [Component.SelectWithSequence]: (
      <DefaultDropdown
        key={control.name}
        {...(control as OOSelectorDropdownModelInterface)}
        sortBySequence={true}
        onChangeCallback={onChange}
      />
    ),
    // not in use
    [Component.SelectWithInfo]: (
      <DefaultDropdown
        key={control.name}
        {...(control as OOSelectorDropdownModelInterface)}
        iconType="INFO"
        onChangeCallback={onChange}
      />
    ),
    [Component.SelectWithHelp]: (
      <DefaultDropdown
        key={control.name}
        {...(control as OOSelectorDropdownModelInterface)}
        iconType="HELP"
        onChangeCallback={onChange}
      />
    ),
    [Component.SelectWithInfoHtml]: (
      <DefaultDropdown
        key={control.name}
        {...(control as OOSelectorDropdownModelInterface)}
        iconType="HELP"
        hasHtmlDescription={true}
        onChangeCallback={onChange}
      />
    ),
    [Component.SelectWithHelpHtml]: (
      <DefaultDropdown
        key={control.name}
        {...(control as OOSelectorDropdownModelInterface)}
        iconType="HELP"
        hasHtmlDescription={true}
        onChangeCallback={onChange}
      />
    ),
    [Component.MultipleSelect]: (
      <DefaultDropdown
        key={control.name}
        {...(control as OOSelectorDropdownModelInterface)}
        iconType="INFO"
        hasHtmlDescription={true}
        onChangeCallback={onChange}
        multiple={true}
      />
    ),
    [Component.SelectWithCheckbox]: (
      <DefaultDropdown
        key={control.name}
        {...(control as OOSelectorDropdownModelInterface)}
        checkBoxItems={true}
        onChangeCallback={onChange}
      />
    ),
  };

  const checkboxControls = {
    [Component.Consent]: (
      <OODefaultConsentCheckbox key={control.name} {...(control as OOSelectorCheckboxModelInterface)} />
    ),
    [Component.Checkbox]: (
      <OODefaultCheckbox
        key={control.name}
        {...(control as OOSelectorCheckboxModelInterface)}
        onChangeCallback={onChange}
      />
    ),
    [Component.CheckboxMultiSelect]: (
      <OOMultiSelectComponent
        key={control.name}
        {...(control as OOMultiSelectModelInterface)}
        onChangeCallback={onChange}
      />
    ),
    // not in use
    [Component.CheckboxList]: <OOCheckboxListHTML {...control} />,
  };

  const autocompleteControls = {
    [Component.Autocomplete]: (
      <DefaultAutoComplete key={control.name} {...(control as OOSelectorDropdownModelInterface)} />
    ),
    [Component.AutocompletePreventDefaultOptions]: (
      <DefaultAutoComplete
        key={control.name}
        {...(control as OOSelectorDropdownModelInterface)}
        preventPopupWithoutInput={true}
      />
    ),
    [Component.ExtendableAutocomplete]: (
      <ExtendableAutoComplete key={control.name} {...(control as OOSelectorDropdownModelInterface)} />
    ),
  };

  const textfieldControls = {
    [Component.Textfield]: (
      <OOTextField
        controlModel={control as TextFieldControlModelInterface}
        key={control.name}
        onChangeCallback={onChange}
      />
    ),
    [Component.WithInfoButton]: (
      <OOTextFieldWithButton
        controlModel={control as TextFieldControlModelInterface}
        onChangeCallback={onChange}
        iconType="Info"
      />
    ),
    [Component.WithHelpButton]: (
      <OOTextFieldWithButton controlModel={control as TextFieldControlModelInterface} iconType="Help" />
    ),
    [Component.MultilineTextfield]: (
      <OOTextField
        controlModel={control as TextFieldControlModelInterface}
        key={control.name}
        onChangeCallback={onChange}
        textFieldProps={{ multiline: true }}
      />
    ),
    [Component.TransformTextToUppercase]: (
      <OOTextField
        controlModel={control as TextFieldControlModelInterface}
        key={control.name}
        onChangeCallback={onChange}
        textFieldProps={{ inputProps: { style: { textTransform: 'uppercase' } } }}
      />
    ),
    [Component.TextfieldWithHtml]: (
      <OOTextField
        controlModel={control as TextFieldControlModelInterface}
        key={control.name}
        onChangeCallback={onChange}
        hasHtmlLabel
      />
    ),
  };

  const accordionControls = {
    [Component.Accordion]: <OOAccordion controlModel={control} />,
    [Component.AccordionWithAddButton]: <AccordionWithAddButton controlModel={control} />,
    [Component.AccordionWithAddButtonOpenModal]: <AccordionWithAddButtonOpenModal controlModel={control} />,
    [Component.AccordionWithSubcontrols]: (
      <AccordionWithSubcontrols controlModel={control} filterSubControls={filterSubControls} />
    ),
  };

  const cardControls = {
    [Component.CardWithButtons]: <CardWithButtons controlModel={control} />,
    [Component.SubCardWithButtons]: <OOCard controlModel={control} />,
  };
  const linkControls = {
    [Component.ConsentWithoutCheckmark]: <DefaultConsentWithoutCheckmark controlModel={control} />,
  };

  const dateControls = {
    [Component.Datepicker]: (
      <DatePickerComponent
        key={control.name}
        {...(control as OODateControlModelInterface)}
        defaultValue={control.defaultValue}
        onChangeCallback={onChange}
      />
    ),
  };

  const toggleControls = {
    [Component.Switch]: <OODefaultSwitch key={control.name} {...control} />,
    [Component.SwitchWithInfo]: <OOSwitchWithInfo key={control.name} {...control} />,
  };

  const otherControls = {
    [Component.Ranking]: <OORankingSurvey validationKey={control.name} key={control.name} {...control} />,
  };

  const map = {
    ...staticControls,
    ...radioControls,
    ...selectControls,
    ...checkboxControls,
    ...autocompleteControls,
    ...textfieldControls,
    ...accordionControls,
    ...linkControls,
    ...dateControls,
    ...toggleControls,
    ...cardControls,
    ...otherControls,
  };

  if (map[control.component]) {
    return map[control.component];
  }

  return <div>{`Unknown control: ${control.component} - ${control.name}`}</div>;
};
