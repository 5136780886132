import React, { useEffect, useMemo, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { useSelector, useStore } from 'react-redux';
import * as Yup from 'yup';
import { Camera } from '@components/app/DocumentSteps/components/Camera';
import { getImageFileFromBlob } from '@components/app/DocumentSteps/utils/helpers';
import { BoxedButton } from '@components/shared/BoxedButton/BoxedButton';
import { ClosePageIcon } from '@components/shared/customIcons/ClosePageIcon';
import { Loader } from '@components/shared/Loader/Loader';
import { useInjection } from '@context/inversify-context-provider';
import { OOStepsEnum } from '@features/oneOnboarding/interfaces';
import { getBlobData } from '@helpers/fileUpload.helper';
import { ChosenFileValidation, validateChosenFile } from '@helpers/fileUpload.helper';
import { getStepperComponent } from '@helpers/getStepperComponent';
import { OnboardingDocumentStatus } from '@helpers/OnboardingSteps';
import { Utils } from '@helpers/utils';
import { mutateUserFlow, useUploadLimitSize } from '@hooks/apiHooks';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, Grid, IconButton, Typography, useTheme } from '@mui/material';
import { RootState } from '@store/rootReducer';
import { useQueryClient } from '@tanstack/react-query';

import { AamBackendApi } from '../../../../libs/aamBackendApi';
import { ReadOnlyDocumentInfo } from '../../components/ReadOnlyDocumentInfo';
import { OOFormControl } from '../../controls/FormControl';
import { controlModelToValidationObject, getDocumentStatus, parseValidationErrors } from '../../helpers';
import { useT } from '../../hooks/useT';
import { OOControlModel } from '../../models/ControlModel';
import { OODocumentationModel } from '../../models/DocumentationModel';
import { OODocumentModel } from '../../models/DocumentModel';
import { OODocumentPartModel } from '../../models/DocumentPartModel';
import { OOFlowWrapper } from '../../wrappers/FlowWrapper';
import { ExampleDocument } from './ExampleDocument';
import { ImagePreview } from './ImagePreview';

interface UploadDocumentProps {
  stepLabel: string;
  stepName: string;
  document: OODocumentModel;
  setDocument: (doc?: OODocumentModel) => void;
  documentationModel: OODocumentationModel;
  setDocumentationModel: (doc?: OODocumentationModel) => void;
  documentationSelected?: (d: OODocumentationModel) => void;
  isLastDocumentToUpload?: boolean;
  callOnSubmit: (documentation?: OODocumentationModel) => Promise<void>;
  isVisibleReadOnly?: boolean;
  nextButtonClicked?: boolean;
  isLoading?: boolean;
  hideDocumentExamples?: boolean;
}

interface State {
  [key: string]: {
    file?: File;
    data?: string | ArrayBuffer | null;
  };
}

export const UploadDocument: React.FC<UploadDocumentProps> = ({
  stepLabel,
  stepName,
  document,
  documentationModel,
  setDocument,
  setDocumentationModel,
  isLastDocumentToUpload,
  callOnSubmit,
  documentationSelected,
  isVisibleReadOnly = true,
  nextButtonClicked,
  hideDocumentExamples,
  ...rest
}) => {
  const [initDataImage, setInitDataImage] = useState(true);
  const [cameraActive, setCameraActive] = useState(false);
  const [cameraPart, setCameraPart] = useState('');
  const [, setPartName] = useState('');
  const [fw, setFw] = useState<boolean>(false);
  const [state, setState] = useState<State>({});
  const [initialValues, setInitialValues] = useState<any>({});
  const [sizeError, setSizeError] = useState<boolean>(false);
  const [emptyFileError, setEmptyFileError] = useState<boolean>(false);
  const [typeError, setTypeError] = useState<boolean>(false);
  const [previewImageUrl, setPreviewImageUrl] = useState<any>({});
  const [isRemoving, setIsRemoving] = useState<boolean>(false);
  const [isFileReuploaded, setIsFileReuploaded] = useState(false);

  const { themeAdditionalData } = useSelector((state: RootState) => state.oneOnboarding);
  const aamBackendApi = useInjection(AamBackendApi);
  const store = useStore<RootState>();

  const documentation = useMemo(() => documentationModel, [documentationModel])!;
  const oneOnboarding = useSelector((state: RootState) => state.oneOnboarding)!;
  const theme = useTheme();
  const { t } = useT('documents', 'entry', 'candidate_recruiter');

  const { clientOrganizationId, selectedConfigurationId, applicationId } = useSelector(
    (state: RootState) => state.oneOnboarding,
  );
  const { data: sizeLimit } = useUploadLimitSize(clientOrganizationId!, selectedConfigurationId!);
  const { userId } = useSelector((state: RootState) => state.authApp);
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading } = mutateUserFlow(
    clientOrganizationId!,
    selectedConfigurationId!,
    userId!,
    applicationId!,
    queryClient,
  );

  const flow = useMemo(() => {
    if (!oneOnboarding.flow) return null;
    return OOFlowWrapper.create(oneOnboarding.flow);
  }, [oneOnboarding]);

  const hasValidationStep = useMemo(() => {
    if (!flow) {
      return false;
    }
    const csIndex = flow.steps.findIndex((step) => step.name === oneOnboarding.step);

    for (const nextStep of flow.steps.slice(csIndex)) {
      if (nextStep.name.toLowerCase().includes('validation')) {
        return true;
      }
    }
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const status = useMemo(() => {
    const result = getDocumentStatus(documentationModel, hasValidationStep);
    return result.status;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentationModel]);

  useEffect(() => {
    const hasControls = document?.controls;
    const isDocumentRejected = document?.userDocument?.additionalData?.status === OnboardingDocumentStatus.REJECTED;

    if (hasControls) {
      const getControlValue = (control: OOControlModel) => {
        if (
          isDocumentRejected &&
          control.name !== 'IDENTIFICATION_NUMBER' &&
          control.name !== 'DOCUMENT_NUMBER' &&
          control.name !== 'SOCIAL_SECURITY_NUMBER' &&
          control.name !== 'IBAN'
        ) {
          return state[control.name] ?? undefined;
        }
        return control.value ?? state[control.name];
      };

      const getInitialValues = (controls: OOControlModel[]) => {
        const controlValues = controls.reduce((acc, control) => {
          acc[control.name] = getControlValue(control);
          return acc;
        }, {} as any);

        Object.keys(state).forEach((partName) => {
          if (state[partName].data) {
            controlValues[partName] = state[partName].data;
          }
        });

        return controlValues;
      };

      setInitialValues(getInitialValues(document.controls));
    }
  }, [state, document?.controls, document?.userDocument?.additionalData?.status]);

  useEffect(() => {
    const downloadImage = async () => {
      if (document?.userDocument?.additionalData?.status !== OnboardingDocumentStatus.REJECTED) {
        try {
          let newState: any = {};
          const results: any[] = await Promise.all(
            document.parts.map((part: OODocumentPartModel) => {
              if (part.userDocumentPart.userDocumentId) {
                const partURL = part.userDocumentPart.userDocumentId + '/' + part.name;
                return aamBackendApi.downloadDocumentWithFullResponse(
                  store.getState().tenant.tenantId,
                  store.getState().authApp.userId!,
                  partURL,
                );
              } else {
                return [];
              }
            }),
          );
          if (results.length > 0) {
            document.parts.forEach((part, i) => {
              const { data, headers } = results[i];
              if (results[i].data) {
                const file = getBlobData(data, headers['content-type'], part.name);
                const fileImage = getImageFileFromBlob(data);
                newState = {
                  ...newState,
                  [part.name]: { ...newState[part.name], file: fileImage, data: file.imageUrl, link: file.link },
                };
              }
            });

            setState({ ...state, ...newState });
          }
        } finally {
          setInitDataImage(false);
          setFw(true);
        }
      } else {
        setFw(true);
      }
    };

    if (initDataImage && document?.parts) {
      downloadImage();
    }
  }, [initDataImage, aamBackendApi, document?.parts, state, store, document?.userDocument?.additionalData?.status]);

  const onRemoveDisabled = useMemo<boolean>(() => {
    return !(
      (status === OnboardingDocumentStatus.MISSING || status === OnboardingDocumentStatus.REVIEW) &&
      Object.keys(state).length
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  if (!documentation) {
    setDocument(undefined);
    setDocumentationModel(undefined);
    return <></>;
  }

  const allRequiredFilesUploaded = document.parts.filter((p) => p.isMandatory).every((p) => !!state[p.name]);

  const partValidations = document.parts.reduce((acc, p) => {
    if (p.isMandatory) {
      acc[p.name] = Yup.string().required(t('documents:validation.required'));
    } else {
      acc[p.name] = Yup.string();
    }
    return acc;
  }, {} as any);

  const validationSchema = Yup.object({
    ...partValidations,
    ...controlModelToValidationObject(document.controls, t, true, flow, 'documents'),
  });

  const selectFile = (event: any, props: FormikProps<any>, part: OODocumentPartModel) => {
    const file = event.target.files[0]!;
    const sizeLimitToMB = sizeLimit ? parseInt(sizeLimit) * 1000000 : 0;
    const fileValidation = validateChosenFile(store.getState().tenant.tenantId, file, {
      allowOnlyImages: false,
      maxSize: sizeLimitToMB,
    });
    if (!fileValidation.valid) {
      onInvalidFileChoose(fileValidation);
      return;
    }
    const reader = new FileReader();
    reader.onload = function (e) {
      props.setFieldValue(part.name, e.target!.result);
      props.validateField(part.name);
      const fileUrl = URL.createObjectURL(file);
      document.controls.forEach((c) => {
        if (props.values[c.name]) {
          state[c.name] = props.values[c.name];
        }
      });
      setState({ ...state, [part.name]: { ...state[part.name], file, data: fileUrl } });
    };
    reader.readAsDataURL(file);
  };

  const cameraPictureTaken = (blob: any, props: FormikProps<any>) => {
    Utils.consoleLog(`Camera picture taken for ${cameraPart}`);
    setCameraActive(false);
    const type = blob.type.split('/')[1];
    const file = new File([blob], `camera_${new Date().getTime()}.${type}`, { type: blob.type });
    const reader = new FileReader();
    reader.onload = function (e) {
      props.setFieldValue(cameraPart, e.target!.result);
      props.validateField(cameraPart);
      document.controls.forEach((c) => {
        if (props.values[c.name]) {
          state[c.name] = props.values[c.name];
        }
      });
      setState({ ...state, [cameraPart]: { ...state[cameraPart], file, data: e.target!.result } });
    };
    reader.readAsDataURL(file);
  };

  const onInvalidFileChoose = (validation: ChosenFileValidation) => {
    if (!validation.valid) {
      setTypeError(validation.typeError);
      setSizeError(validation.sizeError);
      setEmptyFileError(validation.emptyFileError);
    }
  };

  const onInvalidFilePopupClose = () => {
    setSizeError(false);
    setTypeError(false);
    setEmptyFileError(false);
  };

  const onRemoveDocuments = async () => {
    setState({});
    setIsRemoving(true);
    try {
      const data = { currentStep: stepName, currentPage: document.name };
      await aamBackendApi.removeDocument(
        clientOrganizationId!,
        selectedConfigurationId!,
        userId!,
        applicationId!,
        data,
      );
    } finally {
      setIsRemoving(false);
    }
  };

  const isReadOnly = document.userDocument?.isReadOnly;
  const areControlsVisible = (isReadOnly && isFileReuploaded) || !isReadOnly;

  const getSendData = (stepName: string, documentName: string, data: any) => {
    return { currentStep: stepName, currentPage: documentName, data };
  };

  const handleRejectedDocument = async (values: any, stepName: string) => {
    const rejectedDocument = documentationModel.documents?.find(
      (i) => i.userDocument?.additionalData?.status === 'REJECTED',
    );
    if (rejectedDocument) {
      const data = { currentStep: stepName, currentPage: rejectedDocument.name };
      await aamBackendApi.removeDocument(
        clientOrganizationId!,
        selectedConfigurationId!,
        userId!,
        applicationId!,
        data,
      );
    }
  };

  const prepareFilesAndData = (values: any) => {
    const partsNames = document.parts.map((part) => part.name);
    const files: any = {};
    const data: any = {};
    for (const [name, value] of Object.entries(values)) {
      if (partsNames.includes(name)) {
        files[name] = state[name].file;
      } else {
        data[name] = value;
      }
    }
    OOFlowWrapper.prepareValues(document.controls, data);
    return { files, data };
  };

  const handleSubmit = async (sendData: any, files: any) => {
    await mutateAsync({ data: sendData, files });
    await callOnSubmit(!isLastDocumentToUpload && documentation.isMultipleDocumentsEnabled ? documentation : undefined);
  };

  const handleErrors = (err: any, actions: any) => {
    const parsed = parseValidationErrors(t, err);
    if (parsed.errors) {
      const controlStatus: Record<string, any> = {};
      Object.keys(parsed.errors).forEach((x) => {
        let prefix = parsed.errors[x]?.split('.')?.[0].toLowerCase() || '';
        const prefixAlias: any = {
          documents2: 'documents',
          documents3: 'documents',
        };
        if (prefixAlias[prefix]) {
          prefix = prefixAlias[prefix];
        }
        controlStatus[x] = t(`${prefix}:${parsed.errors[x]}`) || '';
      });
      actions.setErrors(controlStatus);
    }
  };

  if (isReadOnly && isVisibleReadOnly) {
    return (
      <ReadOnlyDocumentInfo
        document={document}
        state={state}
        documentationSelected={documentationSelected}
        setDocument={setDocument}
        nextButtonClicked={nextButtonClicked}
      />
    );
  }

  if (!fw) {
    return <Loader />;
  }
  return (
    <>
      <Box m={{ md: 2 }}>
        {getStepperComponent(themeAdditionalData.stepper)}
        <Formik
          enableReinitialize={true}
          validateOnMount={true}
          validateOnChange={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values, actions) => {
            try {
              await handleRejectedDocument(values, stepName);
              const { data, files } = prepareFilesAndData(values);
              const sendData = getSendData(
                documentationModel.uploadEarly ? OOStepsEnum.documentUploadTwo : stepName,
                document.name,
                data,
              );
              await handleSubmit(sendData, files);
            } catch (err) {
              handleErrors(err, actions);
            }
          }}
        >
          {(props) => (
            <form encType="multipart/form-data" onSubmit={props.handleSubmit} style={{ flexGrow: 1 }}>
              <Box
                mb={2}
                display="flex"
                flexDirection="column"
                alignItems="stretch"
                justifyContent="space-between"
                minHeight="80vh"
              >
                <Box>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      {cameraActive && (
                        <Camera onCapture={(blob) => cameraPictureTaken(blob, props)} defaultFacingMode="environment" />
                      )}
                    </Grid>
                  </Grid>
                  {!cameraActive && (
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <h3 style={{ ...themeAdditionalData.smallTitle }}>
                          {stepLabel} - {t(`documents:${document.label}`)}
                        </h3>
                      </Grid>
                      {document.parts.map((part) => (
                        <React.Fragment key={part.name}>
                          {!hideDocumentExamples && (
                            <ExampleDocument
                              state={state}
                              part={part}
                              setPreviewImageUrl={setPreviewImageUrl}
                              previewImageUrl={previewImageUrl}
                            />
                          )}

                          <Grid item xs={12}>
                            <Grid container direction="column" key={part.name}>
                              <Grid>
                                {t(`documents:${part.description}`) && (
                                  <Box mb={2}>
                                    <Typography>{t(`documents:${part.description}`)}</Typography>
                                  </Box>
                                )}
                              </Grid>
                              <Grid container item direction="row" spacing={1}>
                                <Grid item xs={12}>
                                  <Typography style={{ textTransform: 'uppercase' }}>
                                    {t(`documents:${part.label}`)} {part.isMandatory ? '*' : ''}
                                  </Typography>
                                </Grid>

                                <Grid item sm={12} md={12} xs={12}>
                                  <label
                                    htmlFor={`btn-upload-${part.name}`}
                                    className="document-form__action-button-special document-upload-label"
                                  >
                                    {!isRemoving && (
                                      <input
                                        accept={'image/*, application/pdf'}
                                        id={`btn-upload-${part.name}`}
                                        name={part.name}
                                        style={{ display: 'none' }}
                                        type="file"
                                        onChange={(event) => {
                                          setIsFileReuploaded(true);
                                          selectFile(event, props, part);
                                        }}
                                      />
                                    )}
                                    <span
                                      className="tag-ds button-secondary"
                                      onClick={() => setPartName(part.name)}
                                      style={{
                                        width: '100%',
                                        color: themeAdditionalData?.smallTitle?.color,
                                      }}
                                    >
                                      {t('candidate_recruiter:DOCUMENTS.DOCUMENT_UPLOAD.addFiles').toUpperCase()}
                                      <span className="material-icons right">file_upload</span>
                                    </span>
                                  </label>
                                </Grid>
                                {!documentation.hideTakePhotoButton && (
                                  <Grid item sm={12} md={12} xs={12}>
                                    <div className="document-form__action-button-special document-upload-label">
                                      <BoxedButton
                                        component="span"
                                        variant="text"
                                        color="primary"
                                        fullWidth={false}
                                        onClick={() => {
                                          setCameraPart(part.name);
                                          setCameraActive(true);
                                        }}
                                        text={t('candidate_recruiter:DOCUMENTS.DOCUMENT_UPLOAD.takePhoto')}
                                        style={{
                                          color: themeAdditionalData?.smallTitle?.color ?? theme.palette.info.dark,
                                        }}
                                      />
                                    </div>
                                  </Grid>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        </React.Fragment>
                      ))}
                      {areControlsVisible &&
                        allRequiredFilesUploaded &&
                        document.controls
                          .filter((c) => c.isVisibleCandidate)
                          .map((control) => (
                            <Grid xs={12} item key={control.name}>
                              <OOFormControl control={control} />
                            </Grid>
                          ))}
                    </Grid>
                  )}
                </Box>
                <Box py={2}>
                  <Box pt={1}>
                    <Button variant="outlined" color="primary" disabled={onRemoveDisabled} onClick={onRemoveDocuments}>
                      {t('candidate_recruiter:DOCUMENTS.DOCUMENT_UPLOAD.removeDocument')}
                    </Button>
                  </Box>
                  <Box pt={1}>
                    <LoadingButton
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={!props.isValid || isLoading || rest.isLoading}
                      loading={isLoading || rest.isLoading}
                    >
                      {t('candidate_recruiter:DOCUMENTS.DOCUMENT_UPLOAD.continue')}
                    </LoadingButton>
                  </Box>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
        <Box className="document-preview-dialog__container">
          <Dialog open={sizeError || typeError || emptyFileError} onClose={onInvalidFilePopupClose} disablePortal>
            <ClosePageIcon onClick={onInvalidFilePopupClose} className="document-preview-dialog__close_icon" />
            {sizeError && (
              <Typography className="document-form__size-limit-error">
                {t('candidate_recruiter:DOCUMENTS.DOCUMENT_UPLOAD.sizeLimit', { sizeLimit })}
              </Typography>
            )}
            {emptyFileError && (
              <Typography className="document-form__size-limit-error">
                {t('candidate_recruiter:DOCUMENTS.DOCUMENT_UPLOAD.emptyFileError')}
              </Typography>
            )}
            {typeError && (
              <Typography className="document-form__size-limit-error">
                {t('candidate_recruiter:DOCUMENTS.DOCUMENT_UPLOAD.typeLimit')}
              </Typography>
            )}
          </Dialog>
        </Box>
      </Box>
      {Object.keys(state).length > 0 && (
        <Box m={{ md: 2 }}>
          <Box
            mt="10px"
            mb="10px"
            style={{
              display: 'grid',
              justifyItems: 'end',
            }}
          >
            <IconButton onClick={() => onRemoveDocuments()} size="large">
              <CloseIcon />
            </IconButton>
          </Box>
          <Box mb="24px">
            <Typography variant="h2" style={{ textTransform: 'uppercase' }}>
              {t(`documents:${document.label}`)}
            </Typography>
          </Box>
          <div
            style={{
              display: 'grid',
              gap: '1rem',
              gridAutoFlow: 'column',
              gridAutoColumns: 'minmax(0, 1fr)',
            }}
          >
            {document.parts.map((part, index) => (
              <React.Fragment key={index}>
                {state[part.name]?.data && (
                  <Grid>
                    <ImagePreview state={state} part={part} />
                  </Grid>
                )}
              </React.Fragment>
            ))}
          </div>
        </Box>
      )}
    </>
  );
};
