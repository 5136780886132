import React from 'react';
import { useTranslation } from 'react-i18next';
import { Notification as NotificationTagDs, NotificationBody, NotificationHeader } from '@adeccoux/tag-ds';
import { translationHelper } from '@features/selfService/helpers/translationHelper';
import { Typography } from '@mui/material';

import { OOControlModelInterface } from '../../../interfaces';

import styles from './Notification.module.scss';

type NotificationProps = {
  controlModel: OOControlModelInterface;
};

export const Notification: React.FC<NotificationProps> = ({ controlModel }: NotificationProps) => {
  const { t, i18n } = useTranslation(['entry']);
  const getTranslation = (key: string) => translationHelper(key, i18n, t);
  return (
    <NotificationTagDs
      icon={<span className="material-icons">info_outline</span>}
      show={true}
      className={`tag-ds ${styles.notification}`}
      info
    >
      {controlModel.label && <NotificationHeader>{getTranslation(`${controlModel.label}`)}</NotificationHeader>}
      {controlModel.description &&
        (controlModel.link ? (
          <NotificationBody>
            <a href={controlModel.link} target="_blank" rel="noopener noreferrer">
              <span className="consent-link-label">
                <Typography sx={{ color: '#107b9e; !important' }}>
                  {getTranslation(`${controlModel.description}`)}
                </Typography>
              </span>
            </a>
          </NotificationBody>
        ) : (
          <NotificationBody>{getTranslation(`${controlModel.description}`)}</NotificationBody>
        ))}
    </NotificationTagDs>
  );
};
