import React from 'react';

export const TrashIconOutlined: React.FC = (props) => {
  return (
    <svg width="14" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="none">
      <path d="M17,20H3c-0.6,0-1-0.4-1-1V7h2v11h12V7h2v12C18,19.6,17.6,20,17,20z" fill="#273446" />
      <path
        d="M19,3h-6V1c0-0.6-0.4-1-1-1H8C7.4,0,7,0.4,7,1v2H1C0.4,3,0,3.4,0,4s0.4,1,1,1h18c0.6,0,1-0.4,1-1S19.6,3,19,3z"
        fill="#273446"
      />
    </svg>
  );
};
