/* eslint-disable */
import { Buffer } from 'buffer';
import { getImageFileFromBlob } from '@components/app/DocumentSteps/utils/helpers';
import { ControlHeaderEnum, OODashboardData } from '@features/oneOnboarding/interfaces';
import { CampaignModel } from '@features/oneOnboarding/models/CampaignModel';
import { OODocumentPartModel } from '@features/oneOnboarding/models/DocumentPartModel';
import { Import, ImportResponse } from '@features/oneOnboarding/models/Import';
import { UserFlowModel } from '@features/oneOnboarding/models/UserFlow';
import { OOUserFlowModel } from '@features/oneOnboarding/models/UserFlowModel';
import { AppConfig } from '@helpers/appConfig';
import { getBlobData } from '@helpers/fileUpload.helper';
import { Utils } from '@helpers/utils';
import { Onboarding } from '@models/onboarding.model';
import { QuickFilterModel } from '@models/quick-filter.model';
import { SearchResult } from '@models/searchResult.model';
import { CheckUser, UserModel } from '@models/user.model';
import { AdminDashboardFilters } from '@store/slices/oneOnboarding.slice';
import { QueryClient, useMutation, useQueries, useQuery } from '@tanstack/react-query';

import { AamBackendApi } from '../libs/aamBackendApi';
import { SelectedConfiguration } from '@features/selfService/models/configurationModels';
import { UserProfileModel } from '@models/userProfile.model';
import { useDecodedParams } from '@hooks/useDecodedParams';
import { Version } from '@features/oneOnboardingAdmin/components/VersionPicker/VersionPicker';

const utils = new Utils();
const appConfig = new AppConfig(utils);
const aamBackendApi = new AamBackendApi(appConfig, utils);

export const useGetUserFlow = (
  clientOrganizationId: string,
  configurationId: string,
  userId: string,
  applicationId: string,
  refetchOnMount: boolean = true,
  onSuccess?: (data: UserFlowModel) => void,
) =>
  useQuery({
    queryKey: ['getUserFlow', clientOrganizationId, configurationId, userId, applicationId],
    queryFn: () => aamBackendApi.ooGetUserFlow(clientOrganizationId, configurationId, userId, applicationId),
    refetchOnMount,
    enabled: !!clientOrganizationId && !!userId && !!applicationId && !!configurationId,
    onSuccess,
  });

export const useGetOnboardingCheck = (
  clientOrganizationId: string,
  configurationId: string,
  user: CheckUser | undefined,
  version: number,
) => {
  return useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: ['getOnboardingChecked', clientOrganizationId, configurationId, user?.userId],
    queryFn: () => aamBackendApi.checkCandidate(clientOrganizationId, configurationId, [user!], false, {}, version),
    refetchOnMount: true,
    enabled: !!clientOrganizationId && !!configurationId && !!user?.userId,
  });
};

export const useGetOnboardingsWithRelationsMultiple = (
  clientOrganizationId: string,
  configurationId: string,
  userId: string,
  applicationId: string,
  onSuccess?: (data: Onboarding) => void,
) =>
  useQuery({
    queryKey: ['getOnboardingsWithRelationsMultiple', clientOrganizationId, configurationId, applicationId, userId],
    queryFn: () =>
      aamBackendApi.getOnboardingsWithRelationsMultiple(clientOrganizationId, configurationId, applicationId, userId),
    refetchOnMount: true,
    enabled: !!clientOrganizationId && !!userId && !!applicationId && !!configurationId,
    onSuccess,
  });

export const mutateUserFlow = (
  clientOrganizationId: string,
  configurationId: string,
  userId: string,
  applicationId: string,
  queryClient: QueryClient,
  callback?: any,
) =>
  useMutation({
    mutationFn: async (payload: any) => {
      const { data, files } = payload;
      try {
        return await aamBackendApi.updateUserFlow(
          clientOrganizationId,
          configurationId,
          userId,
          data,
          files ?? {},
          applicationId,
        );
      } catch (err) {
        throw err;
      }
    },
    onSuccess: () => {
      callback?.();
      queryClient.invalidateQueries(['getUserFlow', clientOrganizationId, configurationId, userId, applicationId]);
    },
  });

export const mutateRejectUser = (
  clientOrganizationId: string,
  configurationId: string,
  selectedUserId: string,
  applicationId: string,
  rejectReason: string,
  callbacks: () => void,
) =>
  useMutation({
    mutationFn: () =>
      aamBackendApi.ooRejectUser(clientOrganizationId, configurationId, selectedUserId, applicationId, rejectReason),
    onSuccess: () => {
      callbacks();
    },
  });

export const mutateUnRejectUser = (
  clientOrganizationId: string,
  configurationId: string,
  selectedUserId: string,
  applicationId: string,
  callbacks: () => void,
) =>
  useMutation({
    mutationFn: () =>
      aamBackendApi.ooUnRejectUser(clientOrganizationId, configurationId, selectedUserId, applicationId),
    onSuccess: () => {
      callbacks();
    },
  });

export const mutateCompleteStep = (
  clientOrganizationId: string,
  configurationId: string,
  userId: string,
  applicationId: string,
  stepName: string,
  queryClient: QueryClient,
  callbacks?: () => void,
) =>
  useMutation({
    mutationFn: () =>
      aamBackendApi.ooAdminCompleteStepForUser(
        clientOrganizationId,
        configurationId,
        userId,
        { stepName },
        applicationId,
      ),
    onSuccess: () => {
      queryClient.invalidateQueries(['getUserFlow', clientOrganizationId, configurationId, userId, applicationId]);
      queryClient.invalidateQueries([
        'getOnboardingsWithRelationsMultiple',
        clientOrganizationId,
        configurationId,
        applicationId,
        userId,
      ]);
      queryClient.invalidateQueries(['getUserOOFlow', userId, applicationId, clientOrganizationId, configurationId]);
      if (callbacks) {
        callbacks();
      }
    },
  });

export const useGetUser = (tenantId: number | undefined, userId: string, config?: any) =>
  useQuery<UserModel | undefined>({
    queryKey: ['getUser', userId, tenantId],
    queryFn: () => aamBackendApi.fetchUser(tenantId!, userId),
    enabled: !!tenantId && !!userId,
    ...config,
  });
export const useGetUserProfile = (tenantId: number, userId: string, onSuccess?: (data: any) => void) =>
  useQuery<UserProfileModel>({
    queryKey: ['getUserProfile', userId, tenantId],
    queryFn: () => aamBackendApi.getUserProfile(tenantId, userId),
    refetchOnMount: true,
    enabled: !!tenantId && !!userId,
    onSuccess,
  });
export const useGetCountryById = (countryId: string) => {
  return useQuery({
    queryKey: ['getCountryById', countryId],
    queryFn: () => aamBackendApi.ooGetCountryById(countryId),
    enabled: !!countryId,
  });
};
export const useGetProvinces = (data: object, clientId: string, confId: string) => {
  return useQuery({
    queryKey: ['getProvinces', data, clientId, confId],
    queryFn: () => aamBackendApi.getControlItems(ControlHeaderEnum.province, data, clientId, confId),
    enabled: !!clientId,
  });
};
export const useGetMuncipalities = (data: object, clientId: string, confId: string) => {
  return useQuery({
    queryKey: ['getMuncipalities', data, clientId, confId],
    queryFn: () => aamBackendApi.getControlItems(ControlHeaderEnum.municipality, data, clientId, confId),
    enabled: !!clientId,
  });
};
export const useGetJourneysByTenant = (tenantId: number) => {
  return useQuery({
    queryKey: ['getJourneysByTenant', tenantId],
    queryFn: () => aamBackendApi.getJourneysByTenant(tenantId),
    enabled: !!tenantId,
  });
};

export const useGetRequisitionsByTenant = (tenantId: number) => {
  return useQuery({
    queryKey: ['getRequisitionsByTenant', tenantId],
    queryFn: () => aamBackendApi.getRequisitionsByTenant(tenantId),
    enabled: !!tenantId,
  });
};

export const useFetchBranches = (enabled = true) =>
  useQuery({ queryKey: ['getBranches'], queryFn: () => aamBackendApi.fetchBranches(), enabled });

export const useDownloadDocumentWithFullResponse = (tenantId: number, parts: Array<OODocumentPartModel>) => {
  const queries = parts.map((part: OODocumentPartModel) => {
    const key = [
      'part',
      tenantId,
      `${part.userDocumentPart.userDocumentId}/${part.name}`,
      part.userDocumentPart?.metadata?.filename || '',
    ];

    return {
      queryKey: key,
      queryFn: () =>
        aamBackendApi
          .downloadDocumentWithFullResponse(
            tenantId,
            part.userDocumentPart.userId,
            `${part.userDocumentPart.userDocumentId}/${part.name}`,
          )
          .then((response) => {
            const { data, headers } = response;
            const file = getBlobData(data, headers['content-type']);
            const fileImage = getImageFileFromBlob(data);
            return {
              ...file,
              file: fileImage,
              name: part.name,
            };
          }),
      enabled: !!tenantId && !!part.userDocumentPart.userId,
      refetchOnWindowFocus: true,
    };
  });

  return useQueries({ queries });
};

export const mutateStepData = (
  clientOrganizationId: string,
  configurationId: string,
  userId: string,
  applicationId: string,
  queryClient: QueryClient,
) => {
  return useMutation({
    mutationFn: (data: any) =>
      aamBackendApi.updateStepData(clientOrganizationId, configurationId, userId, applicationId, data),
    onSuccess: () => {
      queryClient.invalidateQueries(['getUserFlow', clientOrganizationId, configurationId, userId, applicationId]);
      queryClient.invalidateQueries([
        'getOnboardingsWithRelationsMultiple',
        clientOrganizationId,
        configurationId,
        applicationId,
        userId,
      ]);
      queryClient.invalidateQueries(['getOnboardingChecked', clientOrganizationId, configurationId, userId]);
      queryClient.invalidateQueries(['getUserProfile', userId]);
    },
  });
};
export const mutateOneOnboarding = (
  tenantId: number,
  clientOrganizationId: string,
  configurationId: string,
  userId: string,
  applicationId: string,
  queryClient: QueryClient,
  callbacks?: () => void,
) => {
  return useMutation({
    mutationFn: (data: any) =>
      aamBackendApi.updateOneOnboarding(clientOrganizationId, configurationId, userId, applicationId, data),
    onSuccess: () => {
      queryClient.invalidateQueries(['checkUser', tenantId, clientOrganizationId, configurationId, userId]);
      if (callbacks) callbacks();
    },
  });
};
export const mutateSignDocument = (
  clientOrganizationId: string,
  configurationId: string,
  userId: string,
  applicationId: string,
  lang: string,
) => {
  return useMutation({
    mutationFn: (payload: { document: any; filterName?: string }) =>
      aamBackendApi.getOneSpanOOSignAllDocumentUrl(clientOrganizationId, {
        document: payload.document.name,
        callbackUrl: `${window.location.origin}/oo/recruiter/onespan-callback`,
        returnUrl: window.location.href,
        clientOrganizationId,
        configurationId,
        applicationId,
        additionalReturnParams: {
          candidateId: userId,
          documentId: payload.document.id,
          lang: lang,
          filter: payload?.filterName,
        },
      }),
    onSuccess: (data) => {
      window.location.href = data.url;
    },
  });
};

export const useUserOOFlow = (
  clientOrganizationId: string,
  configurationId: string,
  userId: string,
  applicationId: string,
  callbacks?: (data: OOUserFlowModel) => void,
) => {
  return useQuery({
    queryKey: ['getUserFlow', clientOrganizationId, configurationId, userId, applicationId],
    queryFn: () =>
      aamBackendApi.fetchUserFlowWithCurrentStepInfoAndUpdateRedux(
        userId,
        applicationId,
        clientOrganizationId,
        configurationId,
      ),
    enabled: !!userId && !!applicationId && !!clientOrganizationId && !!configurationId,
    onSuccess: (userFlow) => {
      callbacks?.(userFlow);
    },
  });
};
export const mutateDeleteUser = (tenantId: number, userId: string, callbacks?: (data: any) => void) => {
  return useMutation({
    mutationFn: ({ sendDeletionMail }: { sendDeletionMail: boolean }) =>
      aamBackendApi.create(`/api/tenant/${tenantId}/user/${userId}`, { sendDeletionMail }),
    onSuccess: (data) => {
      callbacks?.(data);
    },
  });
};
export const useFetchImportUsers = (
  selectedConfiguration: string | undefined,
  selectedConfigurationId: string | null,
  importClientOrganizationId: string | null,
  updateRefetchInterval: (interval: number) => void,
  refetchInterval: number,
  tableParameters: {
    page: number;
    limit: number;
    startDate?: number | null;
    importStatus?: string;
    order: {
      direction: string;
      field: string;
    };
  },
  onSuccess?: (data: any) => void,
) => {
  return useQuery({
    queryKey: ['fetchImports', selectedConfiguration, tableParameters],
    queryFn: () => {
      if (!selectedConfiguration) {
        return {};
      }
      const parse = JSON.parse(selectedConfiguration || '');

      return aamBackendApi
        .fetchImportsWithNoRelation(parse.clientOrganizationId, parse.configurationId, tableParameters)
        .then((response) => {
          if (response.results.length && response.results.find((i) => i.status === 'IN_PROGRESS')) {
            updateRefetchInterval(2000);
          } else {
            updateRefetchInterval(0);
          }

          return response;
        });
    },
    enabled: !!selectedConfigurationId && !!importClientOrganizationId,
    refetchOnMount: true,
    refetchInterval,
    onSuccess,
  });
};
export const useCampaign = (url: string, callbacks?: (data: any) => void) => {
  return useQuery({
    queryKey: ['fetchCampaign', url],
    queryFn: () => aamBackendApi.fetchCampaign(url),
    enabled: !!url,
    onSuccess: (data) => {
      callbacks?.(data);
    },
  });
};
export const mutateActivateCampaign = (
  tenantId: number,
  campaign: CampaignModel | undefined,
  callbacks?: (data: any) => void,
) =>
  useMutation({
    mutationFn: () => aamBackendApi.updateCampaign(tenantId, campaign),
    onSuccess: (data) => callbacks?.(data),
  });

export const useUploadLimitSize = (clientOrganizationId: string, selectedConfigurationId: string) =>
  useQuery({
    queryKey: ['useUploadLimitSize', clientOrganizationId, selectedConfigurationId],
    queryFn: () => aamBackendApi.fetchUploadLimitSize(clientOrganizationId, selectedConfigurationId),
    enabled: !!clientOrganizationId && !!selectedConfigurationId,
  });

export const useAllApplicationsForUserAndClientOrgUploadLimitSize = (
  userId: string | null,
  callbacks?: (data: any) => void,
) =>
  useQuery({
    queryKey: ['getOnboardingsWithRelations', userId],
    queryFn: () => aamBackendApi.getAllApplicationsForUserAndClientOrg(userId!),
    enabled: !!userId,
    onSuccess: (data) => callbacks?.(data),
  });
export const useGetOnboardingTheme = (
  organizationId: string | null,
  configurationId: string | null,
  callbacks?: (data: any) => void,
) =>
  useQuery({
    queryKey: ['getOnboardingTheme', organizationId, configurationId],
    queryFn: () =>
      aamBackendApi.getOnboardingTheme(
        organizationId ? organizationId : 'default',
        configurationId ? configurationId : 'default',
      ),
    //enabled: !!organizationId && !!configurationId,
    onSuccess: (data) => callbacks?.(data),
  });

export const useGetRejectReasons = (clientOrganizationId: string, configurationId: string) =>
  useQuery({
    queryKey: ['getRejectReasons'],
    queryFn: () => aamBackendApi.getRejectReasons(clientOrganizationId, configurationId),
  });

export const useFetchSelectedConfiguration = (
  clientOrganizationId: string,
  configurationId: string,
  callbacks?: (data: SelectedConfiguration) => void,
) =>
  useQuery({
    queryKey: ['fetchSelectedConfiguration', clientOrganizationId, configurationId],
    queryFn: () => aamBackendApi.fetchSelectedConfiguration(clientOrganizationId, configurationId),
    enabled: !!clientOrganizationId && !!configurationId,
    onSuccess: (data) => callbacks?.(data),
  });
export const useAllConfigurationsForTenant = (tenantId: number) =>
  useQuery({
    queryKey: ['fetchClientOrganizationForTenantIncludingTenantUnrelated', tenantId],
    queryFn: () => aamBackendApi.fetchClientOrganizationForTenantIncludingTenantUnrelated(tenantId),
    enabled: !!tenantId,
  });

type UseOnboardingFlowUsersOptions = {
  filters: any;
  page: number;
  order: string;
  orderBy: string;
  quickFilter: string;
  onboardingCreatedAtInterval: any;
  rowsPerPage: number;
  quickFilterItemsAvaliable?: any;
  tenantId: number;
  quickFilterItems: Array<QuickFilterModel> | null;
  otherFilters: any;
  journeyId: string;
  searchOnboardingEndpointVersion: Version;
};

export const useOnboardingFlowUsers = (
  {
    filters,
    page,
    order,
    orderBy,
    quickFilter,
    onboardingCreatedAtInterval,
    rowsPerPage,
    quickFilterItemsAvaliable,
    tenantId,
    quickFilterItems,
    otherFilters,
    journeyId,
    searchOnboardingEndpointVersion,
  }: UseOnboardingFlowUsersOptions,
  callbacks?: (data: any) => void,
) =>
  useQuery<SearchResult>({
    queryKey: [
      'getOnboardingFlowUsers',
      filters,
      page,
      order,
      orderBy,
      quickFilter,
      onboardingCreatedAtInterval,
      rowsPerPage,
      quickFilterItemsAvaliable,
      tenantId,
      quickFilterItems,
      otherFilters,
      journeyId,
      searchOnboardingEndpointVersion,
    ],
    queryFn: () => {
      const payload = {
        page: page + 1,
        sortDir: order.toUpperCase(),
        sort: orderBy,
        ...otherFilters,
        ...onboardingCreatedAtInterval,
        filter: quickFilter,
        tile: quickFilter,
        isOO: true,
        clientOrganizationId: filters.clientOrganizationId,
        configurationId: journeyId,
        resultsPerPage: rowsPerPage,
        quickFilterItems: (quickFilterItems || []).map(({ key, title, description, ...rest }) => ({ ...rest })),
      };

      if (searchOnboardingEndpointVersion === Version.v3) {
        return aamBackendApi.fetchOnboardingFlowUsersv3(payload);
      }
      return aamBackendApi.fetchOnboardingFlowUsersv2(tenantId, payload);
    },
    enabled: !!quickFilterItems && quickFilterItems?.length !== 0,
    onSuccess: (data) => callbacks?.(data),
  });

export const useLocationsByRegions = (regionIds: string[]) => {
  return useQuery({
    queryKey: ['locationsByRegions', regionIds],
    queryFn: () => aamBackendApi.getLocationsBySelectedRegions(regionIds),
    enabled: !!regionIds.length,
  });
};

export const useRegions = (enabled = true) => {
  return useQuery({
    queryKey: ['getRegions'],
    queryFn: () => aamBackendApi.fetchRegions(),
    retry: false,
    enabled,
  });
};
export const useSites = (enabled = true) => {
  return useQuery({
    queryKey: ['fetchSites'],
    queryFn: () => aamBackendApi.getSites(),
    retry: false,
    enabled,
  });
};

export const useCampaigns = (enabled = true) => {
  return useQuery({
    queryKey: ['fetchCampaigns'],
    queryFn: () => aamBackendApi.fetchCampaigns(),
    retry: false,
    enabled,
  });
};
export const useAllForDashboard = (tenantId: number) =>
  useQuery({
    queryKey: ['getAllForDashboard', tenantId],
    queryFn: () =>
      aamBackendApi
        .getAllForDashboard(tenantId)
        .then((data: any) => JSON.parse(Buffer.from(data, 'base64').toString()) as OODashboardData),
    refetchOnMount: true,
    retry: true,
  });

export const useDownloadLogo = (tenantId: number, name = 'default.png') =>
  useQuery({
    queryKey: ['downloadLogo', tenantId, name],
    queryFn: () => aamBackendApi.downloadLogo(tenantId, name),
    enabled: !!tenantId,
  });
export const useAllConfigurations = (clientOrganizationId: string) =>
  useQuery({
    queryKey: ['allConfigurations', clientOrganizationId],
    queryFn: () => aamBackendApi.fetchAllConfigurations(clientOrganizationId),
    refetchOnMount: true,
    enabled: !!clientOrganizationId,
  });
export const useAllDraftConfigurations = (clientOrganizationId: string) =>
  useQuery({
    queryKey: ['allDraftConfigurations', clientOrganizationId],
    queryFn: () => aamBackendApi.fetchAllDraftConfigurations(clientOrganizationId),
    refetchOnMount: true,
    enabled: !!clientOrganizationId,
  });
export const useAllDraftConfigurationsByTenantId = (clientOrganizationId: string, tenantId: number) =>
  useQuery({
    queryKey: ['allDraftConfigurations', clientOrganizationId],
    queryFn: () => aamBackendApi.fetchAllDraftConfigurationsByTenantId(clientOrganizationId, tenantId),
    refetchOnMount: true,
  });
export const useFetchAvailableTenantsForRecruiter = (recruiterId?: string | null) =>
  useQuery({
    queryKey: ['fetchAvailableTenantsForRecruiter', recruiterId],
    queryFn: () => aamBackendApi.fetchAvailableTenantsForRecruiter(recruiterId!),
    refetchOnMount: true,
    enabled: !!recruiterId,
  });

export const mutateDraftConfiguration = (
  clientOrganizationId: string,
  draftId: string,
  queryClient: QueryClient,
  callback?: any,
) =>
  useMutation({
    mutationFn: (payload: {
      data: {
        configuration: any;
        clientOrganizationId: string;
        configurationId: string;
        configurationChangeStack: any;
      };
      tenantId: string | null;
    }) =>
      draftId
        ? aamBackendApi.updateDraftConfiguration(clientOrganizationId, draftId, payload)
        : aamBackendApi.createDraftConfiguration(clientOrganizationId, payload),
    onSuccess: () => {
      callback?.();
      queryClient.invalidateQueries(['allDraftConfigurations', clientOrganizationId]);
    },
  });

export const mutateDleteDraftConfiguration = (queryClient: QueryClient) =>
  useMutation({
    mutationFn: (payload: { draftId: string }) => aamBackendApi.deleteDraftConfiguration(payload.draftId),
    onSuccess: () => {
      queryClient.invalidateQueries(['allDraftConfigurations', 'x']);
    },
  });

export const useHasPdfExport = (clientOrganizationId: string, configurationId: string) => {
  return useQuery({
    queryKey: ['getHasPdfExport'],
    queryFn: () => aamBackendApi.hasPdfExport(clientOrganizationId, configurationId),
    retry: true,
    enabled: !!clientOrganizationId && !!configurationId,
  });
};

export const useQuickFilters = (
  onboardingCreatedAtInterval: any,
  adminDashboardFilters: AdminDashboardFilters,
  page: number,
  order: string,
  orderBy: string,
  quickFilter: any,
  rowsPerPage: number,
  quickFilterItemsAvaliable: boolean,
  quickFilterItems: Array<QuickFilterModel> | null,
  otherFilters: Record<any, any>,
  journeyId: string,
  tenantId: number,
) => {
  return useQuery({
    queryKey: [
      'quickFilters',
      onboardingCreatedAtInterval,
      adminDashboardFilters,
      page,
      order,
      orderBy,
      quickFilter,
      rowsPerPage,
      quickFilterItemsAvaliable,
      (quickFilterItems?.map(({ number, ...rest }) => rest) || []) as Array<QuickFilterModel>,
      otherFilters,
      journeyId,
      tenantId,
    ],
    queryFn: () => {
      const payload = {
        page: page + 1,
        sortDir: order.toUpperCase(),
        sort: orderBy,
        ...otherFilters,
        ...onboardingCreatedAtInterval,
        filter: quickFilter,
        tile: quickFilter,
        isOO: true,
        clientOrganizationId: adminDashboardFilters.clientOrganizationId,
        configurationId: journeyId,
        resultsPerPage: rowsPerPage,
        quickFilterItems: quickFilterItems,
      };

      return aamBackendApi.getQuickFilters(tenantId, payload);
    },
    enabled: !!quickFilterItems && quickFilterItems?.length !== 0,
  });
};

export const useGetUserWithPermissions = (tenantId: number, userId: string, callbacks?: (data: UserModel) => void) =>
  useQuery({
    queryKey: ['getUserWithPermissions', tenantId, userId],
    queryFn: () => aamBackendApi.getUserWithPermissions(tenantId, userId),
    onSuccess: (data) => callbacks?.(data),
    enabled: !!userId && userId !== '0',
    retry: true,
    refetchOnMount: true,
  });

export const useGetUserFlowHistory = (clientOrganizationId: string, userId: string) =>
  useQuery({
    queryKey: ['getUserFlowHistory', clientOrganizationId, userId],
    queryFn: () => aamBackendApi.getUserFlowHistory(clientOrganizationId, userId),
    enabled: !!userId,
    retry: true,
    refetchOnMount: true,
  });
export const useGetUserMailHistory = (userId: string, clientOrganizationId: string, configurationId: string) =>
  useQuery({
    queryKey: ['fetchUserMailHistory', userId, clientOrganizationId, userId],
    queryFn: () => aamBackendApi.fetchUserMailHistory(userId, clientOrganizationId, configurationId),
    enabled: !!userId,
    retry: true,
    refetchOnMount: true,
  });
export const useGetUserActionHistory = (
  tenantId: number,
  userId: string,
  applicationId: string,
  clientOrganizationId: string,
  configurationId: string,
) =>
  useQuery({
    queryKey: ['getUserActionHistory', tenantId, userId, applicationId, clientOrganizationId, configurationId],
    queryFn: () =>
      aamBackendApi.getUserActionHistory(tenantId, userId, applicationId, clientOrganizationId, configurationId),
    enabled: !!userId && !!applicationId && !!clientOrganizationId && !!configurationId,
    retry: true,
    refetchOnMount: true,
  });

export const useUserCheck = (onboarding: Onboarding | undefined, version: number = 3) => {
  const userCheck: CheckUser | undefined = onboarding
    ? {
        clientOrganizationId: onboarding.clientOrganizationId,
        userId: onboarding.userId,
        tenantId: onboarding.tenantId,
        applicationId: onboarding.applicationId || '',
        configurationId: onboarding.configurationId,
        rejectedAt: onboarding.rejectedAt,
      }
    : undefined;
  const { clientOrganizationId = '', configurationId = '' } = useDecodedParams();

  const { data: onboardingChecked, refetch: refetchOnboardingCheck } = useGetOnboardingCheck(
    clientOrganizationId,
    configurationId,
    userCheck,
    version,
  );

  return { onboardingChecked, refetchOnboardingCheck };
};

export const useTelemetryStat = (events: any[], dateFrom: string, dateTo: string, clientOrganizationId?: string) =>
  useQuery({
    queryKey: ['telemetryStat', dateFrom, dateTo, events, clientOrganizationId],
    queryFn: () => {
      return aamBackendApi.getTelemetryStat(dateFrom, dateTo, events, clientOrganizationId);
    },
  });
