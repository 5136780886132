import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Loader, Option, Select } from '@adeccoux/tag-ds';
import AuthLayout from '@components/shared/AuthLayout/AuthLayout';
import { initApp } from '@helpers/app.helper';
import {
  getLanguage,
  getTenantByDomain,
  getTenantByDomainInstance,
  getTenantInfo,
  storeTenant,
  tenantMap,
} from '@helpers/tenant.helper';
import { store } from '@store/index';
import { authAppSlice } from '@store/slices/authApp.slice';

import styles from './TenantSwitcher.module.scss';

const TenantSwitcher: React.FC = () => {
  const [renderView, setRenderView] = useState(false);
  const [tenant, setTenant] = useState(getTenantInfo());

  const history = useHistory();
  const { t } = useTranslation(['recruiter']);

  const reInit = async (): Promise<void> => {
    store.dispatch(authAppSlice.actions.clear());
    localStorage.removeItem('clientOrganizationId');
    localStorage.removeItem('selectedConfigurationId');
    localStorage.removeItem('redux_admin_auth_state');
    storeTenant(tenant.tenantId);
    if (i18n?.services?.languageUtils) {
      const lang = getLanguage(tenant.tenantId);
      await i18n.changeLanguage(lang);
    }
    await initApp();
    window.location.href = '/oo/recruiter';
  };

  useEffect(() => {
    const asyncCall = async (): Promise<void> => {
      const tenantByDomain = getTenantByDomain();
      if (tenantByDomain) {
        // no need to re-init, if tenantByDomain exists, initial appInit is valid, so just soft-redirect
        history.push('/oo/recruiter');
      } else {
        setRenderView(true);
      }
    };
    asyncCall();
  }, [history]);

  const handleSelectTenant = (tenant: number) => {
    const tenantId = parseInt(tenant.toString(), 10);

    setTenant({ tenantId, tenantAlias: tenantMap['tid' + tenantId].alias });
  };

  const handleContinue = async (): Promise<void> => {
    if (tenant.tenantId !== getTenantInfo().tenantId) {
      await reInit();
    } else {
      history.push('/oo/recruiter');
    }
  };

  if (!renderView) return <Loader />;

  const options = Object.keys(getTenantByDomainInstance()).map((key) => ({
    [tenantMap[key].name || tenantMap[key].countryCode]: parseInt(key.replace('tid', ''), 10),
  }));

  return (
    <AuthLayout>
      <h2 className={styles.heading}>{t('GENERAL.TENANT_SELECTION.changeCountrySpecificTenantLabel')}</h2>
      <Select
        className={styles.select}
        name="select"
        onChange={(value) => handleSelectTenant(value)}
        placeholder="Select an Option"
        value={tenant.tenantId}
        large
        label={t('GENERAL.TENANT_SELECTION.selectTenant')}
      >
        {options.map((option, i) => {
          const key = Object.keys(option)[0];

          return <Option key={i} value={option[key]} label={key} style={{ width: '100%' }} />;
        })}
      </Select>
      <button className={clsx('button_large', styles.button)} onClick={handleContinue}>
        CONTINUE
      </button>
    </AuthLayout>
  );
};

export default TenantSwitcher;
