import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, useTheme } from '@mui/material';

import { BaseDocumentElementProps } from '../typings';

export interface DocumentStatusProps {
  Icon: React.FC<{ htmlColor: any }>;
  text: string;
  color: string;
}

const DocumentStatus: React.FC<DocumentStatusProps> = ({ Icon, text, color }) => {
  const theme = useTheme();

  return (
    <Box className="document-status__container">
      <Icon htmlColor={color} />
      <Typography className="document-status__description" style={{ fontFamily: theme.typography.fontFamily }}>
        {text}
      </Typography>
    </Box>
  );
};

interface DocumentStatusesProps extends BaseDocumentElementProps {
  statuses: DocumentStatusProps[];
}

export const DocumentStatuses: React.FC<DocumentStatusesProps> = ({ statuses, dictionaries }) => {
  const { t } = useTranslation(dictionaries);
  return (
    <Box className="document-statuses__container">
      {statuses.map((i) => (
        <DocumentStatus key={i.text} Icon={i.Icon} text={t(`statuses.${i.text}`)} color={i.color} />
      ))}
    </Box>
  );
};
