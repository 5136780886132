import React, { useContext, useEffect, useMemo, useState, VoidFunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  dictDocumentStatuses,
  getDocumentStatusIcon,
} from '@components/app/DocumentSteps/components/dictDocumentStatuses';
import { DocumentStatuses } from '@components/app/DocumentSteps/components/DocumentStatuses';
import { Loader } from '@components/shared/Loader/Loader';
import { useInjection } from '@context/inversify-context-provider';
import { getStepperComponent } from '@helpers/getStepperComponent';
import { OnboardingDocumentStatus } from '@helpers/OnboardingSteps';
import { useAllApplicationsForUserAndClientOrgUploadLimitSize, useAllForDashboard } from '@hooks/apiHooks';
import { useTenant } from '@hooks/useComponentMapping';
import useTelemetry from '@hooks/useTelemetry';
import { EventType, TargetName, TelemetryActor } from '@models/telemetry.model';
import { KeyboardArrowRight } from '@mui/icons-material';
import { Box, Button, Grid, useTheme } from '@mui/material';
import { RootState } from '@store/rootReducer';
import { snackbarSlice } from '@store/slices/snackbar';
import { useMutation } from '@tanstack/react-query';

import BottomBox from '../../../components/shared/BottomBox/BottomBox';
import { AamBackendApi } from '../../../libs/aamBackendApi';
import { CandidatePageLayout } from '../components/CandidatePageLayout/CandidatePageLayout';
import { OONavigationContext } from '../context/NavigationContext';
import { HtmlCustom } from '../controls/fields/HtmlCustom';
import { getDocumentStatus } from '../helpers';
import { useFlowWrapper } from '../hooks/useFlowWrapper';
import { mandatoryDocumentCondition } from '../hooks/useRenderCondition';
import { OOStepsEnum } from '../interfaces';
import { OODocumentationModel } from '../models/DocumentationModel';
import { OODocumentModel } from '../models/DocumentModel';
import { OOFlowWrapper } from '../wrappers/FlowWrapper';
import { UploadDocument } from './UploadDocument/UploadDocument';
import { OOUploadDocumentIntro } from './UploadDocumentIntro';
import { OOUploadDocumentTypeSelection } from './UploadDocumentTypeSelection';

const isCompleted = (flow: any[], step: string, currentStep: string) => {
  const stepIndex = flow.findIndex((s) => s.name === step);
  const currentStepIndex = flow.findIndex((s) => s.name === currentStep);
  return stepIndex <= currentStepIndex;
};

export const DocumentUploadPage: VoidFunctionComponent = () => {
  const [document, setDocument] = useState<OODocumentModel | undefined>();
  const [documentationModel, setDocumentationModel] = useState<OODocumentationModel | undefined>();
  const [nextButtonClicked, setNextButtonClicked] = useState<boolean>(false);
  const userId = useSelector((state: RootState) => state.authApp.userId)!;
  const oneOnboarding = useSelector((state: RootState) => state.oneOnboarding);
  const stepsOverviewHidden = useSelector((state: RootState) => state.oneOnboarding.stepsOverviewHidden);
  const { data = [], isFetching } = useAllApplicationsForUserAndClientOrgUploadLimitSize(userId);
  const { themeAdditionalData } = useSelector((state: RootState) => state.oneOnboarding);

  const { clientOrganizationId, applicationId, selectedConfigurationId, stepData } = oneOnboarding;
  const [hasIntro, setHasIntro] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingStepper, setIsLoadingStepper] = useState(true);
  const theme = useTheme();
  const { t } = useTranslation(['documents', 'candidate_recruiter']);
  const aamBackendApi = useInjection(AamBackendApi);
  const navigationContext = useContext(OONavigationContext);
  const history = useHistory();
  const store = useStore<RootState>();
  const flowControls = store.getState().oneOnboarding.flow;
  const { createTelemetryAuthenticated } = useTelemetry();
  const dispatch = useDispatch();

  const stepLabel = stepData?.label
    ? t(`documents:${oneOnboarding?.stepData?.label}`)
    : t('candidate_recruiter:DOCUMENTS.DOCUMENT_UPLOAD.uploadDocument');

  const stepDescription =
    stepData?.description && t(`documents:${oneOnboarding?.stepData?.label}`)
      ? t(`documents:${oneOnboarding?.stepData?.description}`)
      : '';

  useEffect(() => {
    const { flow, step, currentStep } = oneOnboarding;
    const overviewFromHistory = history.location.state?.overview;

    if (!isLoadingStepper) return;

    setIsLoadingStepper(true);

    if (overviewFromHistory === false || stepsOverviewHidden) {
      setHasIntro(false);
    } else {
      setHasIntro(!isCompleted(flow, step, currentStep));
    }
    setIsLoadingStepper(false);
  }, [history, stepsOverviewHidden, oneOnboarding, isLoadingStepper]);

  useEffect(() => {
    createTelemetryAuthenticated({
      applicationId: applicationId,
      clientOrganizationId: clientOrganizationId,
      configurationId: selectedConfigurationId,
      eventType: EventType.PAGE_LOADED,
      tenantId: store.getState().tenant.tenantId,
      targetName: TargetName.CandidateDocumentUpload,
      actor: TelemetryActor.CANDIDATE,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { mutate: completeStepForUser, isLoading: isCompleting } = useMutation({
    mutationFn: () =>
      aamBackendApi.ooAdminCompleteStepForUser(
        clientOrganizationId as any,
        selectedConfigurationId as any,
        userId as any,
        {
          stepName: oneOnboarding.step,
        },
        applicationId as any,
      ),
    onSuccess: () => {
      navigationContext.forward();
    },
  });

  const tenantId = useTenant();
  const { data: dashboardData } = useAllForDashboard(tenantId);
  const shouldBeValidated = (doc: OODocumentModel): boolean => {
    return !!(
      doc.userDocument?.isReadOnly && doc.userDocument?.additionalData?.status === OnboardingDocumentStatus.VALIDATING
    );
  };

  const isVisible = (doc: OODocumentationModel): boolean => {
    return OOFlowWrapper.isRecruiter() || doc?.uploadableByCandidate;
  };

  const fw = useFlowWrapper();

  const { hideDocumentExamples } = useMemo(() => {
    const configuration = dashboardData?.configurations.find(
      (config) => config.id === selectedConfigurationId && config.clientOrganizationId === clientOrganizationId,
    );
    return { ...configuration?.additionalConfigurations };
  }, [dashboardData, selectedConfigurationId, clientOrganizationId]);

  const hasValidationStep = useMemo(() => {
    if (!fw) {
      return false;
    }
    const csIndex = fw.steps.findIndex((step) => step.name === oneOnboarding.step);
    for (const nextStep of fw.steps.slice(csIndex)) {
      if (nextStep.name.toLowerCase().includes('validation')) {
        return true;
      }
    }
    return false;
  }, [fw, oneOnboarding.step]);

  if (!fw) {
    return <></>;
  }

  const step = fw.getDocumentStep(oneOnboarding.step)!;
  const documentsUpload2Step = fw.getDocumentStep(OOStepsEnum.documentUploadTwo)!;
  let additionalDocumentations: OODocumentationModel[] = [];
  if (step.name === OOStepsEnum.documentUpload && documentsUpload2Step?.uploadEarly) {
    additionalDocumentations = documentsUpload2Step.documentations.map((item) => ({
      ...item,
      uploadEarly: true,
      isMandatory: false,
    }));
  }

  if (!step) {
    return <div>No step found</div>;
  }
  const documentationSelected = (d: OODocumentationModel, doc?: OODocumentModel) => {
    let uploadedDocument = d.documents.filter((d) => (d.userDocument ? d : null));
    if (d.groupDocuments) {
      uploadedDocument = [];
    }
    setDocumentationModel(d);
    const hasRejectedDocuments = d.documents.some(
      (d) => d.userDocument?.additionalData?.status === OnboardingDocumentStatus.REJECTED,
    );
    if (doc) {
      setDocument(doc);
    } else if (d.documents.length === 1) {
      setDocument(d.documents[0]);
    } else if (!(d.documents.length >= 1 && uploadedDocument.length === 0) && !hasRejectedDocuments) {
      const existingDocument = d.documents.find((item) => !!item.userDocument);
      setDocument(existingDocument);
    }
  };
  const missingMandatoryDocumentation = step.documentations
    .filter((x) => isVisible(x))
    .filter((documentation: OODocumentationModel) => {
      const isMandatory = documentation.isMandatory;
      return documentation.documents.every(
        (document: OODocumentModel) =>
          isMandatory &&
          (!document.userDocument ||
            document.userDocument?.additionalData?.status === OnboardingDocumentStatus.REJECTED),
      );
    });

  const documentationsForValidating = step.documentations
    .filter((x) => isVisible(x))
    .filter((documentation: OODocumentationModel) => {
      return documentation.documents.some((document: OODocumentModel) => shouldBeValidated(document));
    });

  const missingOrRejectedDocumentation = step.documentations
    .filter((x) => isVisible(x))
    .filter((documentation: OODocumentationModel) => {
      return documentation.documents.every((document: OODocumentModel) => {
        const isMandatoryRejected =
          document.isMandatory && document?.userDocument?.additionalData?.status === OnboardingDocumentStatus.REJECTED;
        return !document.userDocument || isMandatoryRejected;
      });
    });

  const onSubmit = async () => {
    setNextButtonClicked(true);
    if (missingMandatoryDocumentation.length > 0) {
      documentationSelected(missingMandatoryDocumentation[0]);
    } else if (documentationsForValidating.length) {
      const doc = documentationsForValidating[0].documents.find((item) => shouldBeValidated(item));
      documentationSelected(documentationsForValidating[0], doc);
    } else {
      if (step.minOptionalDocumentations) {
        const numberOfUploadedOptionalDocumentations = step.documentations
          .filter((x) => isVisible(x))
          .filter((documentation: OODocumentationModel) => {
            const isMandatory = documentation.isMandatory;
            return documentation.documents.some((document: OODocumentModel) => !isMandatory && document.userDocument);
          }).length;
        if (numberOfUploadedOptionalDocumentations >= step.minOptionalDocumentations) {
          completeStepForUser();
        } else if (step.minOptionalDocumentationErrorMessage) {
          dispatch(
            snackbarSlice.actions.showError({
              message: t(step.minOptionalDocumentationErrorMessage),
            }),
          );
          //here we can add message for candidate if he didn't uploaded optional documents
        }
      } else {
        completeStepForUser();
      }
    }
  };

  const onSubmitAuto = async (documentation?: OODocumentationModel) => {
    try {
      setIsLoading(true);
      const flow = await aamBackendApi.fetchUserFlowWithCurrentStepInfoAndUpdateRedux(userId);
      const flowWrapper = OOFlowWrapper.create(flow.selectedConfiguration.flow);
      const stepFetched = flowWrapper.getDocumentStep(oneOnboarding.step)!;

      const updatedDocumentations = stepFetched.documentations.filter((x) => isVisible(x));

      const missingOrRejectedDocuments = updatedDocumentations.filter((documentation: OODocumentationModel) =>
        documentation.documents.some((document: OODocumentModel) => {
          const isMandatoryAndMissing = documentation.isMandatory && !document.userDocument;
          const isRejectedAndMandatory =
            documentation.isMandatory &&
            document?.userDocument?.additionalData?.status === OnboardingDocumentStatus.REJECTED;
          return isMandatoryAndMissing || isRejectedAndMandatory;
        }),
      );

      if (missingOrRejectedDocuments.length === 0) {
        completeStepForUser();
      }
    } finally {
      goBackFromUploadDocument(documentation);
      setIsLoading(false);
    }
  };

  const getDocumentSubLabel = (isMandatory: boolean, status: string) => {
    if (isMandatory && status === OnboardingDocumentStatus.MISSING) {
      return t(`documents:MANDATORY`);
    } else {
      return t(`documents:${status}`);
    }
  };

  const goBackFromUploadDocument = (documentation?: OODocumentationModel) => {
    setDocument(undefined);
    setDocumentationModel(documentation);
  };

  if (documentationModel && !document) {
    return (
      <CandidatePageLayout overrideBack={goBackFromUploadDocument}>
        <OOUploadDocumentTypeSelection
          setDocument={setDocument}
          documentationModel={documentationModel}
          setDocumentationModel={setDocumentationModel}
        />
      </CandidatePageLayout>
    );
  }

  if (documentationModel && document) {
    return (
      <CandidatePageLayout overrideBack={goBackFromUploadDocument}>
        <UploadDocument
          hideDocumentExamples={hideDocumentExamples}
          isLoading={isLoading || isCompleting}
          stepLabel={stepLabel}
          stepName={step.name}
          document={document}
          setDocument={setDocument}
          documentationModel={documentationModel}
          setDocumentationModel={setDocumentationModel}
          isLastDocumentToUpload={missingOrRejectedDocumentation.length <= 1}
          callOnSubmit={onSubmitAuto}
          documentationSelected={documentationSelected}
          nextButtonClicked={nextButtonClicked}
          isVisibleReadOnly={document.userDocument?.additionalData?.status !== OnboardingDocumentStatus.REJECTED}
        />
      </CandidatePageLayout>
    );
  }

  if (isCompleting || isLoadingStepper || (isFetching && !data.length)) {
    return <Loader />;
  }

  if (hasIntro) {
    return <OOUploadDocumentIntro goNext={() => setHasIntro(false)} />;
  }

  const checkDisabledDocumentsForDE = () => {
    const currentStep = store.getState().oneOnboarding.currentStep;
    const currentIndex = fw.steps.findIndex((s) => s.name === currentStep);
    const docUpload2Index = fw.steps.findIndex((s) => s.name === OOStepsEnum.documentUploadTwo);
    const enabledPath = history.location.pathname.includes('oo/flow/document-upload2');
    if (!enabledPath && docUpload2Index !== -1 && currentIndex >= docUpload2Index) {
      return true;
    }
    return false;
  };

  return (
    <CandidatePageLayout>
      <Box
        m={{ md: 2 }}
        display="flex"
        flexDirection="column"
        alignItems="stretch"
        justifyContent="space-between"
        minHeight="80vh"
      >
        <Box>
          {oneOnboarding?.themeAdditionalData?.stepper
            ? getStepperComponent(oneOnboarding?.themeAdditionalData?.stepper)
            : ''}
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Box style={{ paddingTop: 12 }}>
                <h3 style={{ ...themeAdditionalData.smallTitle }} className="tag-ds ">
                  {stepLabel}
                </h3>
              </Box>
            </Grid>
            {stepDescription && (
              <Grid item xs={12}>
                <Box>
                  <HtmlCustom rawHtml={stepDescription} />
                </Box>
              </Grid>
            )}
            {!oneOnboarding?.themeAdditionalData?.hideDocumentStatuses && (
              <Grid item xs={12} style={{ paddingTop: 24 }}>
                <DocumentStatuses dictionaries={['documents']} statuses={dictDocumentStatuses} />
              </Grid>
            )}
            <Grid item xs={12}>
              {step.documentations
                .concat(additionalDocumentations)
                .filter((x) => isVisible(x))
                .map((document) => {
                  document.isMandatory = mandatoryDocumentCondition(document, flowControls) || document.isMandatory;
                  return document;
                })
                .map((d, index) => {
                  const { disabled, status } = getDocumentStatus(d, hasValidationStep);
                  const disableDocuments = checkDisabledDocumentsForDE();
                  return (
                    <Box
                      key={index}
                      className="document-button__main_container"
                      onClick={() => (!disabled && !disableDocuments ? documentationSelected(d) : '')}
                      style={{
                        cursor: disabled || disableDocuments ? 'default' : 'pointer',
                        borderColor: `${theme.palette.text.disabled}`,
                        paddingBottom: '16px',
                      }}
                    >
                      <Box display="flex" alignItems="center" width="100%" justifyContent="space-between">
                        <div className="document-button__right_container1">
                          {getDocumentStatusIcon(d.isMandatory, status)}
                          <span
                            className="tag-ds  base"
                            style={{
                              color: disableDocuments ? theme.palette.text.disabled : '',
                              fontFamily: theme.typography.fontFamily,
                            }}
                          >
                            {t(`documents:${d.label}`)}
                          </span>
                        </div>

                        <div className="document-button__right_container2">
                          {!disabled && <KeyboardArrowRight fontSize="small" />}
                        </div>
                      </Box>
                      {oneOnboarding?.themeAdditionalData.showStatusSubLabel && (
                        <div className="tag-ds  base" style={{ marginLeft: 27 }}>
                          {getDocumentSubLabel(d.isMandatory, status)}
                        </div>
                      )}
                    </Box>
                  );
                })}
            </Grid>
          </Grid>
        </Box>
        <BottomBox width="100%" py={2}>
          <Button disabled={isCompleting} variant="contained" color="primary" onClick={onSubmit}>
            {t('candidate:GENERAL.PAGES.nextPage')}
          </Button>
        </BottomBox>
      </Box>
    </CandidatePageLayout>
  );
};
