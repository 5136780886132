import React, { useMemo } from 'react';
import clsx from 'clsx';
import { useFormikContext } from 'formik';
import { Option, Select } from '@adeccoux/tag-ds';
import { FormControl, FormGroup, FormHelperText, InputLabel, MenuItem, useTheme } from '@mui/material';
import { store } from '@store/index';

import { selectErrors } from '../../../helpers';
import { editableCondition } from '../../../hooks/useRenderCondition';
import { useT } from '../../../hooks/useT';
import { OOFlowWrapper } from '../../../wrappers/FlowWrapper';
import { FormControlLoader } from '../../FormControlLoader';

import styles from './SelectCheckbox.module.scss';

type OOSelectCheckboxProps = {
  isEditableCandidate?: boolean;
  isEditableRecruiter?: boolean;
  validationKey: string;
  label: string;
  description: string;
  menuItems?: JSX.Element[];
  mandatory: boolean;
  loading: boolean;
  defaultValue?: any;
  onChangeCallback?: (event: any) => void;
  multiple?: boolean;
  rootProps?: any;
  checkBoxItems?: boolean;
};

export const OOSelectCheckbox: React.FC<OOSelectCheckboxProps> = (props) => {
  const {
    isEditableCandidate,
    isEditableRecruiter,
    label,
    mandatory,
    menuItems,
    description,
    loading,
    validationKey,
    defaultValue,
    onChangeCallback,
    multiple,
    checkBoxItems,
    rootProps,
  } = props;
  const formik = useFormikContext<Record<string, any>>();
  const fieldProps = defaultValue
    ? { ...formik.getFieldProps(validationKey), defaultValue }
    : formik.getFieldProps(validationKey);

  const fieldMeta = formik.getFieldMeta(validationKey);
  const flowData = store.getState().oneOnboarding.flow;
  const helperText = fieldMeta && fieldMeta.touched && fieldMeta.error;
  let prefix = label.split('.')[0].toLowerCase();
  const prefixAlias: any = {
    documents2: 'documents',
    documents3: 'documents',
  };
  if (prefixAlias[prefix]) {
    prefix = prefixAlias[prefix];
  }

  const flowWrapper = useMemo(() => {
    if (!flowData) return null;
    return OOFlowWrapper.create(flowData);
  }, [flowData]);

  const isDisabled = !(
    flowWrapper?.isControlEditable(isEditableCandidate ?? true, isEditableRecruiter ?? true) ?? true
  );

  const isEditable = editableCondition(rootProps, flowWrapper, formik.values) ?? true;

  const { t, translationsLoading } = useT('entry', 'approval', 'hiring');
  const theme = useTheme();
  const items = useMemo(() => {
    const output = [...(menuItems || [])];
    // TODO: migration to mui 5 issue
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (theme.props?.MuiFormControl?.variant === 'standard' && !fieldProps.value) {
      output.unshift(
        checkBoxItems ? (
          <Option label={t(`${prefix}:${label}`)} value="" />
        ) : (
          <MenuItem value="" disabled>
            {t(`${prefix}:${label}`)}
          </MenuItem>
        ),
      );
    }
    return output;
  }, [checkBoxItems, fieldProps.value, label, menuItems, prefix, t, theme]);

  if (translationsLoading || loading) {
    return <FormControlLoader />;
  }

  if (defaultValue !== undefined && !formik.values[validationKey]) {
    formik.setFieldValue(validationKey, defaultValue);
  }

  const defaultEmptyValue = multiple ? [] : '';

  const value = formik.values[validationKey] || defaultEmptyValue;

  return (
    <FormControl>
      <FormGroup>
        <InputLabel disabled={isDisabled} sx={{ top: '-22px;!important' }}>
          {t(`${prefix}:${label}`)}
          {mandatory && ' *'}
        </InputLabel>
        <Select
          {...fieldProps}
          {...selectErrors(fieldMeta)}
          className={clsx('tag-ds', 'visible-scroll', styles.selectCheckbox)}
          placeholder={t('candidate_recruiter:GENERAL.CONTROLS.selectAnOption')}
          onChange={(event) => {
            formik.setFieldValue(validationKey, event);
            if (onChangeCallback) {
              onChangeCallback(event);
            }
          }}
          value={value}
          displayEmpty={true}
          disabled={isDisabled || !isEditable}
          multiple={multiple}
        >
          {items}
        </Select>
      </FormGroup>
      <FormHelperText error>
        {helperText && validationKey === 'documentType' ? t(`documents:validations.chooseOptionList`) : helperText}
      </FormHelperText>
      {description && <FormHelperText>{t(`${prefix}:${description}`)}</FormHelperText>}
    </FormControl>
  );
};
