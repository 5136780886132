import React, { useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import { useInjection } from '@context/inversify-context-provider';
import { getBlobData } from '@helpers/fileUpload.helper';
import { Typography, useTheme } from '@mui/material';
import { Skeleton } from '@mui/material';
import { RootState } from '@store/rootReducer';

import { AamBackendApi } from '../../../../libs/aamBackendApi';
import { useT } from '../../hooks/useT';
import { OOControlModelInterface } from '../../interfaces';

type OOImageComponentProps = {
  controlModel: OOControlModelInterface;
};

export const OOImageComponent: React.FC<OOImageComponentProps> = ({ controlModel }: OOImageComponentProps) => {
  const { t } = useT('entry');
  const theme = useTheme();
  const aamBackendApi = useInjection(AamBackendApi);
  const store = useStore<RootState>();
  const [imgSrc, setImgSrc] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const downloadImage = async () => {
      if (!controlModel.imageSrc) {
        return;
      }
      try {
        setLoading(true);
        const { data, headers } = await aamBackendApi.downloadLogo(
          store.getState().tenant.tenantId,
          controlModel.imageSrc,
        );
        setImgSrc(getBlobData(data, headers['content-type']).imageUrl);
      } catch (err) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    downloadImage();
  }, [aamBackendApi, controlModel?.imageSrc, store]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center',
        minHeight: 225,
      }}
    >
      {controlModel?.imageSrc && (
        <div
          style={{
            width: `calc(30% + 32px)`,
            marginBottom: '32px',
          }}
        >
          {loading && (
            <div
              style={{
                height: 0,
                overflow: 'hidden',
                paddingTop: '100%',
                position: 'relative',
              }}
            >
              <Skeleton
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                }}
                animation="wave"
                width="100%"
                variant="circular"
                component="div"
                height={150}
              />
            </div>
          )}
          {imgSrc && !loading && (
            <img
              style={{
                borderRadius: '50%',
                width: '100%',
                height: 'auto',
                objectFit: 'contain',
              }}
              src={imgSrc}
              alt="preview"
            />
          )}
        </div>
      )}
      <Typography
        align="center"
        style={{
          fontSize: '24px',
          fontFamily: theme.typography.fontFamily || 'Roboto',
          color: '#030c18',
          lineHeight: '32px',
          fontWeight: 400,
        }}
      >
        {t(controlModel.label)}
      </Typography>
      <Typography
        align="center"
        variant="body1"
        style={{
          fontSize: '14px',
          fontFamily: theme.typography.fontFamily || 'Roboto',
          color: '#030c18',
          lineHeight: '24px',
          fontWeight: 400,
        }}
      >
        {t(controlModel.description)}
      </Typography>
    </div>
  );
};
