import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Loader } from '@components/shared/Loader/Loader';
import { useInjection } from '@context/inversify-context-provider';
import { OONavigationPages } from '@features/oneOnboarding/interfaces';
import { getStepperComponent } from '@helpers/getStepperComponent';
import { SignDocumentsStatus } from '@helpers/OnboardingSteps';
import { useAllApplicationsForUserAndClientOrgUploadLimitSize } from '@hooks/apiHooks';
import useTelemetry from '@hooks/useTelemetry';
import { EventType, TargetName, TelemetryActor } from '@models/telemetry.model';
import { Box, Button } from '@mui/material';
import { RootState } from '@store/rootReducer';
import { useMutation } from '@tanstack/react-query';

import BottomBox from '../../../../components/shared/BottomBox/BottomBox';
import { AamBackendApi } from '../../../../libs/aamBackendApi';
import { CandidatePageLayout } from '../../components/CandidatePageLayout/CandidatePageLayout';
import { OONavigationContext } from '../../context/NavigationContext';
import { useFlowWrapper } from '../../hooks/useFlowWrapper';
import { DocumentsForSignatureModel, OOStepModel } from '../../models/StepModel';
import { OOSignDocumentStart } from './SignatureStart';
import { SignDocumentButton } from './SignDocumentButton';
import { SignDocumentPreview } from './SignDocumentPreview';

const dictionaries = ['documents', 'hiring', 'approval'];

const isCompleted = (flow: any[], step: string, currentStep: string) => {
  const stepIndex = flow.findIndex((s) => s.name === step);
  const currentStepIndex = flow.findIndex((s) => s.name === currentStep);
  return stepIndex < currentStepIndex;
};

export const OOCandidateSignDocuments: React.FC<any> = () => {
  const [selectedDoc, setSelectedDoc] = useState<null | any>(null);
  const [hasIntro, setHasIntro] = useState(false);
  const [isLoadingStepper, setIsLoadingStepper] = useState(true);

  const aamBackendApi = useInjection(AamBackendApi);
  const location = useLocation();
  const { t, i18n } = useTranslation([...dictionaries, 'stepper']);
  const navigationContext = React.useContext(OONavigationContext);
  const { userId } = useSelector((state: RootState) => state.authApp);
  const { data = [], isFetching } = useAllApplicationsForUserAndClientOrgUploadLimitSize(userId);
  const { clientOrganizationId, selectedConfigurationId, step, flow, currentStep, applicationId } = useSelector(
    (state: RootState) => state.oneOnboarding,
  );
  const stepsOverviewHidden = useSelector((state: RootState) => state.oneOnboarding.stepsOverviewHidden);
  const { themeAdditionalData } = useSelector((state: RootState) => state.oneOnboarding);
  const query = new URLSearchParams(location.search);
  const signed = query.get('signed');
  const redirectToNextStep = query.get('redirectToNextStep');
  const history = useHistory();
  const { createTelemetryAuthenticated } = useTelemetry();
  const tenantId = localStorage.getItem('tenantId');

  useEffect(() => {
    createTelemetryAuthenticated({
      applicationId: applicationId,
      clientOrganizationId: clientOrganizationId,
      configurationId: selectedConfigurationId,
      eventType: EventType.PAGE_LOADED,
      tenantId: tenantId as any,
      targetName: TargetName.DocumentsToSign,
      actor: TelemetryActor.CANDIDATE,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsLoadingStepper(true);
    const overviewFromHistory = history.location.state?.overview;

    if (overviewFromHistory === false || stepsOverviewHidden) {
      setHasIntro(false);
    } else {
      setHasIntro(!isCompleted(flow, step, currentStep));
    }

    setIsLoadingStepper(false);
  }, [history, currentStep, flow, step, stepsOverviewHidden]);

  const { mutate: signDocument, isLoading, isSuccess, isError } = useMutation({
    mutationFn: (document: any) =>
      aamBackendApi.getOneSpanOOSignAllDocumentUrl(clientOrganizationId || '', {
        document: document.name,
        callbackUrl: `${window.location.origin}/oo/onespan-callback`,
        returnUrl: window.location.href,
        clientOrganizationId,
        configurationId: selectedConfigurationId,
        applicationId: applicationId || localStorage.getItem('applicationId'),
        additionalReturnParams: { documentId: document.id, lang: i18n.language, signerId: userId },
      }),
    onSuccess: (data) => {
      window.location.href = data.url;
    },
  });

  const {
    mutate: completeStepForUser,
    isLoading: isCompleting,
    isSuccess: isCompletingSuccess,
    isError: isCompletingFailed,
  } = useMutation({
    mutationFn: () =>
      aamBackendApi.ooCompleteDocumentForSignatureStep(
        clientOrganizationId as any,
        selectedConfigurationId as any,
        userId as any,
        {
          stepName: step,
        },
      ),
    onSuccess: (response) => {
      if (response.currentStep === 'FEEDBACK_STEP') {
        const feedbackStep = response.selectedConfiguration?.flow?.find(
          (step: OOStepModel) => step.name === 'FEEDBACK_STEP',
        );
        navigationContext.to(`FEEDBACK_STEP_${feedbackStep?.pages?.[0]?.name}` as OONavigationPages);
        return;
      }
      navigationContext.forward();
    },
  });

  useEffect(() => {
    if (redirectToNextStep === 'true' && clientOrganizationId && step) {
      history.push({ pathname: history.location.pathname, search: '' });
      location.search = '';
      completeStepForUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completeStepForUser, history, redirectToNextStep, clientOrganizationId]);

  const flowWrapper = useFlowWrapper();

  const documentsForSignature: DocumentsForSignatureModel[] = useMemo(() => {
    if (!flowWrapper) {
      return [];
    }
    const stepData = flowWrapper.getStep(step);
    if (!stepData) {
      return [];
    }
    return stepData.documentsForSignature;
  }, [flowWrapper, step]);

  const stepLabelKey: string = useMemo(() => {
    if (!flowWrapper) {
      return 'candidate_recruiter:APPROVAL.SIGNATURE.signDocuments';
    }
    const stepData: any = flowWrapper.getStep(step);
    if (!stepData) {
      return 'candidate_recruiter:APPROVAL.SIGNATURE.signDocuments';
    }
    return `stepper:${stepData.label}`;
  }, [flowWrapper, step]);

  const currentPageAndStep = useMemo(() => {
    return flowWrapper?.getCurrentStepAndFormFromNavigationPage(navigationContext.currentPage);
  }, [navigationContext, flowWrapper]);

  const areAllDocsSignedByCandidate = useMemo(() => {
    return documentsForSignature
      .filter((d: any) => d.isCandidateSignatureRequired)
      .map((d: any) => d?.userDocuments?.find((doc: any) => doc.additionalData.signed === true))
      .every((d: any) => d?.additionalData?.signed === true);
  }, [documentsForSignature]);

  if (selectedDoc) {
    return (
      <SignDocumentPreview
        selectedDoc={selectedDoc}
        selectedDocName={t(`${selectedDoc.label}`)}
        goBack={() => setSelectedDoc(null)}
      />
    );
  }

  const isIntroVisible = hasIntro && !signed;

  if (isLoadingStepper || (isFetching && !data.length)) return <Loader />;

  if (isIntroVisible) return <OOSignDocumentStart close={() => setHasIntro(false)} />;

  return (
    <>
      {isCompleting || isLoading || (isSuccess && !isError) ? (
        <Loader />
      ) : (
        <CandidatePageLayout hideOverview={false}>
          <Box
            m={{ md: 2 }}
            display="flex"
            flexDirection="column"
            alignItems="stretch"
            justifyContent="space-between"
            minHeight="80vh"
          >
            <Box>
              {getStepperComponent(themeAdditionalData.stepper)}
              {(themeAdditionalData.stepper === 'none' ||
                !currentPageAndStep?.stepLabel ||
                !i18n.exists(currentPageAndStep?.stepLabel, { ns: 'stepper' })) && (
                <Box display="flex" justifyContent="left" width="100%" style={{ marginBottom: 40, marginTop: 12 }}>
                  <h3 className="tag-ds">{t(stepLabelKey)}</h3>
                </Box>
              )}

              <Box display="flex" justifyContent="center" width="100%">
                <Box width="100%">
                  {documentsForSignature.map((doc: DocumentsForSignatureModel, i: number) => {
                    if (!doc) {
                      return <></>;
                    }
                    const prefix = doc?.label.split('.')[0].toLowerCase();
                    const userDocuments = [...(doc.userDocuments || [])].sort(
                      (a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
                    );
                    const documentToUse = userDocuments[0];
                    const isDocumentSigned = documentToUse?.additionalData?.signed === true;

                    const getStatus = () => {
                      if (isDocumentSigned) {
                        return SignDocumentsStatus.SIGNED;
                      }
                      if (!doc.isCandidateSignatureRequired) {
                        return SignDocumentsStatus.PREVIEW;
                      }
                      if (doc.isCandidateSignatureRequired && !isDocumentSigned) {
                        return SignDocumentsStatus.SIGNATURE_MISSING;
                      }

                      return SignDocumentsStatus.MISSING;
                    };
                    return (
                      <SignDocumentButton
                        isMandatory={doc.isCandidateSignatureRequired}
                        key={i}
                        dictionaries={dictionaries}
                        title={t(`${prefix}:${doc.label}`)}
                        status={getStatus()}
                        statusClassName={`document-button__status document-button__status--${getStatus().toLowerCase()}`}
                        disabled={isDocumentSigned}
                        onClick={async () => {
                          const d = { ...doc };
                          if (isDocumentSigned || !doc.isCandidateSignatureRequired) {
                            setSelectedDoc(d);
                          } else {
                            if (documentToUse) {
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-ignore
                              d.id = documentToUse.id;
                            }
                            signDocument(d);
                          }
                        }}
                      />
                    );
                  })}
                </Box>
              </Box>
            </Box>
            <BottomBox py={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => completeStepForUser()}
                disabled={
                  ((isCompleting || isCompletingSuccess) && !isCompletingFailed) || !areAllDocsSignedByCandidate
                }
              >
                {t('candidate:GENERAL.PAGES.nextPage')}
              </Button>
            </BottomBox>
          </Box>
        </CandidatePageLayout>
      )}
    </>
  );
};
