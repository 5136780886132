import { useSelector } from 'react-redux';
import { RootState } from '@store/rootReducer';

export enum Tenant {
  Germany = 109,
  DISAGGermany = 131,
  France = 107,
  Italy = 115,
  UK = 184,
  Spain = 174,
  Netherlands = 108,
  Belgium = 113,
  Australia = 208,
  OfficeAnglesUK = 188,
  Unknown,
}

export function useTenant(): Tenant {
  const tenantId = useSelector((state: RootState) => state.tenant.tenantId);
  const numToEnum: Tenant[] = [];
  numToEnum[109] = Tenant.Germany;
  numToEnum[131] = Tenant.DISAGGermany;
  numToEnum[107] = Tenant.France;
  numToEnum[115] = Tenant.Italy;
  numToEnum[184] = Tenant.UK;
  numToEnum[174] = Tenant.Spain;
  numToEnum[108] = Tenant.Netherlands;
  numToEnum[113] = Tenant.Belgium;
  numToEnum[208] = Tenant.Australia;
  numToEnum[188] = Tenant.OfficeAnglesUK;
  return numToEnum[tenantId] || Tenant.Unknown;
}
